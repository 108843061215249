


























import { Component, Vue, Prop } from 'vue-property-decorator';

@Component({})
export default class RadialProgressBar extends Vue {
  @Prop({ type: Number, default: 10 }) public value!: number;
  @Prop({ type: Number, default: 100 }) public width!: number;
  @Prop({ type: Number, default: 100 }) public height!: number;

  public radius: number = 30;

  get x(): number {
    return this.width / 2;
  }

  get y(): number {
    return this.height / 2;
  }

  // Full circle =  2 * π * radius
  public circumference = 2 * Math.PI * this.radius;
}
