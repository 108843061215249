var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('Container',{attrs:{"title":"User Administration"},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('h1',[_vm._v("User Administration")]),(
        _vm.isUserAllowed(
          _vm.PermissionsMatrixActionsEnum.CREATE,
          'user_administration-users-create_users_to_products'
        )
      )?_c('div',{class:_vm.$style.buttonContainer},[_c('BaseModal',{attrs:{"title":"Generate Invitation Links","size":"large"},on:{"close":_vm.resetButtonState},scopedSlots:_vm._u([{key:"openBtn",fn:function(ref){
      var openModal = ref.openModal;
return [_c('button',{class:[_vm.$style.bulkInviteLink, 'button'],on:{"click":openModal}},[_vm._v(" Generate Invitation Link ")])]}},{key:"modalBody",fn:function(ref){
      var closeModal = ref.closeModal;
return [_c('GenerateInvitationForm',{ref:"GenerateInvitationForm",attrs:{"products":_vm.products},on:{"isUserListValid":_vm.isGenerateInvitationFormValid,"disableGenerateLink":_vm.resetButtonState,"uploadComplete":_vm.handleGenerateButton,"closeModal":closeModal,"watchGenerateInviteError":_vm.watchGenerateInviteError}})]}},{key:"modalFoot",fn:function(){return [_c('button',{staticClass:"button is-primary",attrs:{"type":"submit","disabled":_vm.buttonDisabled},on:{"click":function () {
                _vm.$refs.GenerateInvitationForm.handleGenerate();
              }}},[_vm._v(" Generate Invitation Link ")])]},proxy:true}],null,false,1296174572)}),_c('BaseModal',{attrs:{"title":"Bulk Invite","size":"large"},on:{"close":_vm.resetInviteButtonState},scopedSlots:_vm._u([{key:"openBtn",fn:function(ref){
              var openModal = ref.openModal;
return [_c('button',{class:[_vm.$style.bulkInviteLink, 'button'],attrs:{"data-test":"bulk-import-button"},on:{"click":openModal}},[_vm._v(" Bulk Invite ")])]}},{key:"modalBody",fn:function(ref){
              var closeModal = ref.closeModal;
return [_c('InvitationListUploader',{ref:"GenerateBulkInvitationForm",attrs:{"products":_vm.products},on:{"isUserListValid":_vm.isInvitationUserListValid,"disableGenerateLink":_vm.resetInviteButtonState,"uploadBulkComplete":_vm.handleBulkGenerateButton,"closeModal":closeModal,"watchInviteError":_vm.watchInviteError}})]}},{key:"modalFoot",fn:function(){return [_c('button',{staticClass:"button is-primary",attrs:{"type":"submit","disabled":_vm.buttonInviteDisabled},on:{"click":function () {
                _vm.$refs.GenerateBulkInvitationForm.handleInviteUsers();
              }}},[_vm._v(" Invite ")])]},proxy:true}],null,false,587756899)}),_c('InviteUserButton',{attrs:{"products":_vm.products,"data-test":"individual-invite"}})],1):_vm._e()]},proxy:true}])},[_c('b-tabs',{class:_vm.$style.tabContainer,attrs:{"animated":false},model:{value:(_vm.activeTab),callback:function ($$v) {_vm.activeTab=$$v},expression:"activeTab"}},[(
        _vm.isUserAllowed(
          _vm.PermissionsMatrixActionsEnum.READ,
          'user_administration-users-read_all_users-read_user_list',
          undefined,
          undefined,
          undefined,
          undefined,
          true
        )
      )?_c('b-tab-item',{attrs:{"label":"Users","disabled":_vm.disableTab}},[_c('UserTab',{on:{"activateTab":_vm.activateTab}})],1):_vm._e(),(
        _vm.isUserAllowed(
          _vm.PermissionsMatrixActionsEnum.READ,
          'user_administration-users-read_all_users-read_user_invitations',
          undefined,
          undefined,
          undefined,
          undefined,
          true
        )
      )?_c('b-tab-item',{attrs:{"label":"Invitations","disabled":_vm.disableTab}},[_c('InvitationTab',{on:{"activateTab":_vm.activateTab}})],1):_vm._e(),(
        _vm.isUserAllowed(
          _vm.PermissionsMatrixActionsEnum.READ,
          'user_administration-subscriptions',
          undefined,
          undefined,
          undefined,
          undefined,
          true
        )
      )?_c('b-tab-item',{attrs:{"label":"Subscriptions","disabled":_vm.disableTab}},[_c('SubscriptionTab',{on:{"activateTab":_vm.activateTab}})],1):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }