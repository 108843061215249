var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:_vm.$style.container},[_c('p',{class:_vm.$style.titleContainer},[_c('b',{class:_vm.$style.title},[_vm._v(" "+_vm._s(_vm.fileName)+" successfully uploaded. ")]),_c('a',{on:{"click":_vm.onClickReupload}},[_vm._v("Reupload file")])]),_c('div',{staticClass:"columns"},[_c('div',{staticClass:"column"},[_c('b-field',{attrs:{"label":"Products"}},[_c('b-select',{attrs:{"data-test":"bulk-import-product-dropdown","placeholder":"Select a name","expanded":""},on:{"input":_vm.handleSelect},model:{value:(_vm.selectedProduct.productId),callback:function ($$v) {_vm.$set(_vm.selectedProduct, "productId", $$v)},expression:"selectedProduct.productId"}},_vm._l(([_vm.defaultProductOptions ].concat( _vm.products)),function(product){return _c('option',{key:product.id,domProps:{"value":product.id}},[_vm._v(" "+_vm._s(product.name)+" ")])}),0)],1)],1),_vm._t("options")],2),_c('div',{class:_vm.$style.listContainer},[_c('table',{staticClass:"table is-fullwidth"},[_c('thead',[_c('tr',[_c('th',[_vm._v("First Name")]),_c('th',[_vm._v("Last Name")]),_c('th',{class:_vm.$style.emailColumn},[_vm._v("Email Address")]),_c('th',[_vm._v("Product Selection")]),_c('th')])]),_c('tbody',_vm._l((_vm.syncedUserList),function(users,index){return _c('tr',{key:users.id},[_c('td',[_c('b-field',{class:_vm.$style.inputField,attrs:{"type":!_vm.isValid(_vm.syncedUserList[index].firstName, 'firstName').isValid
                  ? 'is-danger'
                  : '',"message":_vm.isValid(_vm.syncedUserList[index].firstName, 'firstName')
                  .errorMessage}},[_c('b-input',{attrs:{"type":"text"},model:{value:(_vm.syncedUserList[index].firstName),callback:function ($$v) {_vm.$set(_vm.syncedUserList[index], "firstName", $$v)},expression:"syncedUserList[index].firstName"}})],1)],1),_c('td',[_c('b-field',{class:_vm.$style.inputField,attrs:{"type":!_vm.isValid(_vm.syncedUserList[index].lastName, 'lastName').isValid
                  ? 'is-danger'
                  : '',"message":_vm.isValid(_vm.syncedUserList[index].lastName, 'lastName')
                  .errorMessage}},[_c('b-input',{attrs:{"type":"text"},model:{value:(_vm.syncedUserList[index].lastName),callback:function ($$v) {_vm.$set(_vm.syncedUserList[index], "lastName", $$v)},expression:"syncedUserList[index].lastName"}})],1)],1),_c('td',[_c('b-field',{class:_vm.$style.inputField,attrs:{"type":!_vm.isValid(_vm.syncedUserList[index].email, 'email').isValid
                  ? 'is-danger'
                  : '',"message":_vm.isValid(_vm.syncedUserList[index].email, 'email').errorMessage}},[_c('b-input',{attrs:{"type":"email"},model:{value:(_vm.syncedUserList[index].email),callback:function ($$v) {_vm.$set(_vm.syncedUserList[index], "email", $$v)},expression:"syncedUserList[index].email"}})],1)],1),_c('td',[_c('b-field',{class:_vm.$style.excludedContainer,attrs:{"type":_vm.checkProducts(users) ? 'is-danger' : undefined,"message":_vm.checkProducts(users) ? 'User is under exclusion period' : ''}},[_c('div',{staticClass:"control"},[_c('div',{staticClass:"select"},[_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.syncedUserList[index].productId),expression:"syncedUserList[index].productId"}],class:_vm.$style.selectPadding,attrs:{"placeholder":"Select a product","expanded":""},on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.$set(_vm.syncedUserList[index], "productId", $event.target.multiple ? $$selectedVal : $$selectedVal[0])}}},_vm._l(([_vm.defaultProductOptions ].concat( _vm.products)),function(product){return _c('option',{key:product.id,domProps:{"value":product.id}},[_vm._v(" "+_vm._s(product.name)+" ")])}),0)])])])],1),_c('td',{class:_vm.$style.removeImportedButton},[_c('a',{staticClass:"icon has-text-grey fa fa-times",on:{"click":function($event){return _vm.removeImportedUser(index)}}})])])}),0)])]),_c('div',{class:_vm.$style.bulkInviteBtn},[_vm._t("footer")],2)])}
var staticRenderFns = []

export { render, staticRenderFns }