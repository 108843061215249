import dayjs from 'dayjs';
import { saveAs } from 'file-saver';
import Vue from 'vue';
import Component from 'vue-class-component';
import XLSX from 'xlsx';
import { CsvXlxs } from '../../../../utils/csv-xlxs.util';

@Component({})
export class InvitationHandler extends Vue {
  get processWorkbook() {
    return CsvXlxs.processWorkbook;
  }
  get loadCsv() {
    return (file: File, emailIndex: number) =>
      CsvXlxs.loadCsv(file, ['firstName', 'lastName', 'email'], emailIndex);
  }
  get loadXlsx() {
    return (file: File, emailIndex: number) =>
      CsvXlxs.loadXlsx(file, ['firstName', 'lastName', 'email'], emailIndex);
  }

  get toBlob() {
    return CsvXlxs.toBlob;
  }

  public downloadSampleXlsx() {
    CsvXlxs.downloadSampleXlsx(
      [
        ['firstName', 'lastName', 'email'],
        ['John', 'Doe', 'johndoe1@mailnator.com'],
        ['Zac', 'Braff', 'zacbraff1@mailnator.com'],
        ['James', 'Dorean', 'jamesdorean@mailnator.com']
      ],
      'Sample',
      {
        Title: 'Bulk user import sample',
        Subject: 'Sample File',
        Author: 'Snappymob'
      }
    );
  }

  public downloadSampleCsv() {
    CsvXlxs.downloadSampleCsv(
      [
        ['firstName', 'lastName', 'email'],
        ['John', 'Doe', 'johndoe1@mailnator.com'],
        ['Zac', 'Braff', 'zacbraff1@mailnator.com'],
        ['James', 'Dorean', 'jamesdorean@mailnator.com']
      ],
      'Sample'
    );
  }

  public downloadAsCsv(data: any[]) {
    const worksheet = XLSX.utils.json_to_sheet(data);
    const csv = XLSX.utils.sheet_to_csv(worksheet);

    const csvBlob = this.toBlob(csv);

    saveAs(
      new Blob([csvBlob], { type: 'application/octet-stream' }),
      `${dayjs(new Date()).format('YYYY-MM-DD')}_invitations.csv`
    );
  }

  public downloadAsXlsx(data: any[]) {
    const workbook = XLSX.utils.book_new();
    workbook.Props = {
      Title: 'Invitations List',
      Subject: 'Invitations File',
      Author: 'Snappymob'
    };
    workbook.SheetNames.push('First');
    // tslint:disable-next-line: no-string-literal
    workbook.Sheets['First'] = XLSX.utils.json_to_sheet(data, {
      header: ['firstName', 'lastName', 'email', 'link'],
      skipHeader: false
    });

    const xlsxWorkbook = XLSX.write(workbook, {
      bookType: 'xlsx',
      type: 'binary'
    });
    const xlsxBlob = this.toBlob(xlsxWorkbook);
    saveAs(
      new Blob([xlsxBlob], { type: 'application/octet-stream' }),
      `${dayjs(new Date()).format('YYYY-MM-DD')}_invitations.xlsx`
    );
  }
}
