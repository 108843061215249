
























































































import { Component, Vue, Prop, Watch, Model } from 'vue-property-decorator';
import { State, Getter, Action, Mutation, namespace } from 'vuex-class';
import { mixins } from 'vue-class-component';
import BaseTable from '@/components/BaseTable.vue';
import BasePagination from '@/components/base/BasePagination.vue';
import BaseLoading from '@/components/base/BaseLoading.vue';
import { PaginationMixin } from '@/components/base/pagination.mixin';
import { capitalize, get as _get } from 'lodash';
import dayjs from 'dayjs';
import {
  GetSubscriptionsPayload,
  Subscription
} from '../../../store/modules/admin/types/user-subscription.types';
import { SubscriptionStatus } from '../../../jbi-shared/types/jaas-oauth2.types';
import { RootState } from '@/store/store';
import TableActionDropdown from '@/components/TableActionDropdown.vue';
import SortableTableHeader from '@/components/SortableTableHeader.vue';
import EditSubscriptionsButton from './EditSubscriptionsButton.vue';
import { ExtendProductSubscriptionPayload } from '@/store/modules/subscription/subscription.state';

@Component({
  components: {
    BaseTable,
    BasePagination,
    BaseLoading,
    TableActionDropdown,
    SortableTableHeader,
    EditSubscriptionsButton
  }
})
export default class SubscriptionList extends mixins(PaginationMixin) {
  sortHeaders = [
    { placeholder: 'Email', sortColumn: 'email', sort: true },
    { placeholder: 'Username', sortColumn: 'username', sort: true },
    { placeholder: 'Application', sortColumn: 'applicationId', sort: true },
    { placeholder: 'Product', sortColumn: 'productName', sort: true },
    {
      placeholder: 'Subscription Period',
      sortColumn: 'startSubscription',
      sort: true
    },
    { placeholder: 'Type', sortColumn: 'subscriptionType', sort: true },
    {
      placeholder: 'Group',
      sortColumn: 'groupName',
      sort: true
    },
    { placeholder: 'Status', sort: false }
  ];
  @Prop() public items!: Subscription[];
  @Prop(Number) public currentPage!: number;
  @Prop(Number) public totalNumberOfPage!: number;
  @Prop(Number) public totalCount!: number;
  @Prop(Boolean) public isFirstPage!: boolean;
  @Prop(Boolean) public isLastPage!: boolean;
  @Prop(Number) public startItemIndex!: number;
  @Prop(Number) public endItemIndex!: number;
  @Prop(String) public sortColumn!: string;
  @Prop(String) public sortOrder!: 'ASC' | 'DESC';

  @Action('subscription/extendProductSubscription')
  public extendProductSubscription!: (
    data: ExtendProductSubscriptionPayload
  ) => Promise<void>;

  @Action('admin/getSubscriptions')
  public getSubscriptions!: (
    params: Partial<GetSubscriptionsPayload>
  ) => Promise<void>;

  @State(({ admin }: RootState) => admin.apiState.getSubscriptions.success)
  public getUserSubscriptionsSuccess!: boolean;

  get dayjs() {
    return dayjs;
  }

  get capitalize() {
    return capitalize;
  }

  get SubscriptionStatus() {
    return SubscriptionStatus;
  }

  public async handleResetQuery() {
    const params = {
      email: this.$route.query.email as string,
      username: this.$route.query.username as string,
      productId: +this.$route.query.productId,
      groupProductId: +this.$route.query.groupProductId,
      applicationId: this.$route.query.applicationId as string,
      status: this.$route.query.subscriptionStatus as string,
      limit: +this.$route.query.limit,
      page: +this.$route.query.page,
      sortColumn: this.$route.query.sortColumn as string,
      sortOrder: this.$route.query.sortOrder as 'ASC' | 'DESC'
    };

    await this.getSubscriptions(params);
  }

  public isInternalStatus({ subscriptionStatus }: Subscription) {
    return [SubscriptionStatus.BLOCKED, SubscriptionStatus.ACTIVE].includes(
      subscriptionStatus!
    );
  }

  public isIndividualSub({ type }: Subscription) {
    return type === 'individual';
  }

  public getSubscriptionStatus(subscription: Subscription) {
    if (subscription.subscriptionStatus === SubscriptionStatus.ACTIVE) {
      const remainingDays = dayjs(subscription.endedAt).diff(new Date(), 'day');

      return remainingDays <= 7
        ? `Expiring (${remainingDays} days)`
        : subscription.subscriptionStatus;
    }
    return subscription.subscriptionStatus;
  }

  public handleToggleSubscription(subscription: Subscription) {
    this.$emit('update', {
      subscription,
      status:
        subscription.subscriptionStatus === SubscriptionStatus.ACTIVE
          ? SubscriptionStatus.BLOCKED
          : SubscriptionStatus.ACTIVE
    });
  }

  public onClickHeaderColumn(columnName: string) {
    if (this.sortColumn === columnName) {
      return this.$emit('sort', {
        sortColumn: columnName,
        sortOrder: this.sortOrder === 'ASC' ? 'DESC' : 'ASC'
      });
    }
    this.$emit('sort', {
      sortColumn: columnName,
      sortOrder: 'DESC'
    });
  }

  public getSortOrderOfColumn(name: string) {
    if (this.sortColumn === name) {
      return this.sortOrder;
    }
    return 'ASC';
  }
}
