

























































































































































import { Component, Prop } from 'vue-property-decorator';
import { Action, State } from 'vuex-class';
import dayjs from 'dayjs';
import BasePagination from '@/components/base/BasePagination.vue';
import BaseLoading from '@/components/base/BaseLoading.vue';
import { RootState } from '@/store/store';
import BaseModal from '@/jbi-shared/vue-components/BaseModal.vue';
import { mixins } from 'vue-class-component';
import { PaginationMixin } from '@/components/base/pagination.mixin';
import UserInvitationHistoryList from './UserInvitationHistoryList.vue';
import { DialogProgrammatic as Dialog } from 'buefy';
import { Pagination, User } from '@/store/modules/admin/types/admin.types';
import ReInviteForm from './ReInviteForm.vue';
import { Product } from '@/store/modules/application/types/application.types';
import BaseTable from '@/components/BaseTable.vue';
import {
  ConvertWKUserPayload,
  WKUser
} from '@/store/modules/sumari/sumari.types';
import TableActionDropdown from '@/components/TableActionDropdown.vue';
import SortableTableHeader from '@/components/SortableTableHeader.vue';
import { isUserAllowed } from '@/utils/rbac.util';
import { PermissionsMatrixActionsEnum } from '@/store/modules/roles-and-permissions/types/roles-and-permissions.types';

@Component({
  computed: {
    PermissionsMatrixActionsEnum() {
      return PermissionsMatrixActionsEnum;
    }
  },
  methods: { isUserAllowed },
  components: {
    BaseModal,
    BasePagination,
    BaseTable,
    BaseLoading,
    TableActionDropdown,
    SortableTableHeader
  }
})
export default class UsersList extends mixins(PaginationMixin) {
  sortHeaders = [
    { placeholder: 'Email', sortColumn: 'email', sort: true },
    {
      placeholder: 'Username',
      sortColumn: 'username',
      sort: true
    },
    { placeholder: 'First Name', sortColumn: 'firstName', sort: true },
    { placeholder: 'Last Name', sortColumn: 'lastName', sort: true },
    {
      placeholder: 'Email Verification',
      sortColumn: 'emailVerification',
      sort: true
    },
    { placeholder: 'User Status', sort: false },
    { placeholder: 'Access', sort: false }
  ];

  @Prop() public items!: User[];
  @Prop(Number) public currentPage!: number;
  @Prop(Number) public totalNumberOfPage!: number;
  @Prop(Number) public totalCount!: number;
  @Prop(Boolean) public isFirstPage!: boolean;
  @Prop(Boolean) public isLastPage!: boolean;
  @Prop(Number) public startItemIndex!: number;
  @Prop(Number) public endItemIndex!: number;
  @Prop(String) public selectedUserType!: string;
  @Prop(String) public sortColumn!: string;
  @Prop(String) public sortOrder!: 'ASC' | 'DESC';

  @Action('admin/toggleUser')
  public toggleUser!: (params: {
    userId: number;
    email: string;
  }) => Promise<void>;

  @Action('sumari/convertToMyJbiUser')
  public convertToMyJbiUser!: (userId: ConvertWKUserPayload) => Promise<void>;

  @Action('admin/verifyUser')
  public verifyUser!: (userId: number) => Promise<void>;

  @State(({ admin }: RootState) => admin.apiState.getUsers.success)
  public getUsersSuccess!: boolean;

  @State(({ admin }: RootState) => admin.userDetails)
  public userList!: Pagination<User>;

  get products(): Product[] {
    const products =
      (this.$store.state as RootState).application.productApplications
        ?.products || [];
    return products.filter((p) => p.isActive && !p.isDeleted);
  }

  public toggleStatus({ userId, email, status, username }: User) {
    const text = status === 'Inactive' ? 'Activate' : 'Deactivate';

    return Dialog.confirm({
      title: `${text} ${username}`,
      message: `Are you sure you want to ${text.toLowerCase()} user <b>${username}</b>?`,
      confirmText: text,
      type: 'is-primary',
      onConfirm: async () => {
        await this.toggleUser({
          userId,
          email
        });
      }
    });
  }

  public async toggleVerifyUser({ userId, emailVerified, email }: User) {
    const text = emailVerified ? 'Unverify' : 'Verify';

    return Dialog.confirm({
      title: `${text} ${email}`,
      message: `Are you sure you want to ${text.toLowerCase()} <b>${email}</b>?`,
      confirmText: text,
      type: 'is-primary',
      onConfirm: async () => {
        await this.verifyUser(userId);
      }
    });
  }

  public handleConvertToUser(user: WKUser) {
    return Dialog.confirm({
      title: `Convert to Internal User`,
      message: `Are you sure you want to convert user ${user.email} to an internal user?`,
      confirmText: 'Convert',
      type: 'is-primary',
      onConfirm: async () => {
        await this.convertToMyJbiUser({
          userId: user.userId,
          firstName: user.firstName,
          lastName: user.lastName,
          email: user.email,
          // @ts-ignore
          username: user.username.replaceAll('wk_', '')
        });
      }
    });
  }

  public async handleViewUserInvitations({ email }: User) {
    this.$buefy.modal.open({
      parent: this,
      component: UserInvitationHistoryList,
      hasModalCard: true,
      trapFocus: true,
      props: {
        email
      }
    });
  }

  public async handleInviteUserToProduct(user: User) {
    this.$buefy.modal.open({
      parent: this,
      component: ReInviteForm,
      hasModalCard: true,
      trapFocus: true,
      props: {
        products: this.products,
        email: user.email,
        firstName: user.firstName,
        lastName: user.lastName
      }
    });
  }

  public async handleEditProfileByAdmin(user: User) {
    this.$router.push({
      name: 'UserProfile',
      params: { user: JSON.stringify(user), isAdminUser: 'true' }
    });
  }

  public invitationStatus(invt: any): any {
    const expiredAt = dayjs(new Date(invt.expiredAt));
    const expired = dayjs().isAfter(expiredAt);

    if (invt.acceptedAt) {
      return 'Active';
    }

    if (expired) {
      return 'Expired';
    }

    return 'Pending';
  }

  public onClickHeaderColumn(columnName: string) {
    if (this.sortColumn === columnName) {
      return this.$emit('sort', {
        sortColumn: columnName,
        sortOrder: this.sortOrder === 'ASC' ? 'DESC' : 'ASC'
      });
    }
    this.$emit('sort', {
      sortColumn: columnName,
      sortOrder: 'DESC'
    });
  }

  public getSortOrderOfColumn(name: string) {
    if (this.sortColumn === name) {
      return this.sortOrder;
    }
    return 'ASC';
  }
}
