









































import { Vue, Watch, Prop } from 'vue-property-decorator';
import { Action, State } from 'vuex-class';
import Component, { mixins } from 'vue-class-component';
import { ApiState } from '@/store/types/general.types';
import { RootState } from '@/store/store';
import RadialProgressBar from '@/jbi-shared/vue-components/RadialProgressBar.vue';
import UploadedInvitedUserTable from './UploadedInvitedUserTable.vue';
import { isTruthy, isDifferent } from '@/jbi-shared/util/watcher.vue-decorator';
import { ToastProgrammatic as Toast } from 'buefy';
import XLSX from 'xlsx';
import { cloneDeep as _cloneDeep } from 'lodash';
import {
  InviteUserToProductPayload,
  BulkInviteUserToProductPayload,
  CheckProductStatus
} from '@/store/modules/admin/types/admin.types';
import { isEmpty } from 'rxjs/operators';
import { saveAs } from 'file-saver';
import { InvitationHandler } from './invitation.mixin';
import CsvImportGuide from '@/components/CsvImportGuide.vue';
import FileUpload from '@/components/FileUpload.vue';
import { CsvXlxs } from '../../../../utils/csv-xlxs.util';
import { handleUserInviteListUploading } from '@/utils/invite-users-upload.util';
import { Job } from 'bull';

@Component({
  components: {
    RadialProgressBar,
    UploadedInvitedUserTable,
    CsvImportGuide,
    FileUpload
  }
})
export default class InvitationListUploader extends mixins(InvitationHandler) {
  @Prop() public products!: object;
  public isLoading = false;

  public dropFiles: File[] | null = null;
  public fileData: InviteUserToProductPayload[] | null = null;

  @State(
    (state: RootState) =>
      state.admin.apiState.bulkInviteNewUserToProduct.success
  )
  public inviteNewUserToProductSuccess!: boolean;

  @Action('admin/uploadUserListFile')
  public uploadUserListFile!: (file: File) => Promise<string>;

  @Action('admin/validateUserInvite')
  public validateUserInvite!: (payload: any) => Promise<Job>;

  @Action('admin/bulkInviteNewUserToProduct')
  public bulkInviteNewUserToProduct!: (
    data: BulkInviteUserToProductPayload
  ) => Promise<void>;

  public resetFile() {
    this.fileData = null;
    this.dropFiles = null;
  }

  isUserListValid(isValid: boolean) {
    if (!this.fileData) {
      this.$emit('isUserListValid', false);
    } else {
      this.$emit('isUserListValid', isValid);
    }
  }

  @Watch('inviteNewUserToProductSuccess')
  @isDifferent
  @isTruthy
  public async watchBulkInviteSuccess(value: File[]) {
    this.$emit('closeModal');
  }

  @Watch('dropFiles')
  @isTruthy
  public async onFilesAdded(file: File) {
    const isCsv = CsvXlxs.isCsv(file);
    const isXlsx = CsvXlxs.isXlsx(file);

    try {
      if (isCsv || isXlsx) {
        const gcsURL = await this.uploadUserListFile(file);

        const job = await this.validateUserInvite({
          fileLink: gcsURL,
          emailIndex: 2
        });

        this.fileData = (await handleUserInviteListUploading.call(
          this,
          job
        )) as InviteUserToProductPayload[];

        this.$emit('uploadBulkComplete', true);
        if (!this.fileData) {
          this.$emit('isUserListValid', false);
        }
      } else {
        throw Error(`Invalid File Format`);
      }
    } catch (error) {
      Toast.open({
        queue: true,
        type: 'is-danger',
        position: 'is-top',
        message: error?.response?.data?.message || error,
        duration: 5000
      });
    }
  }

  public async handleInviteUsers() {
    if (!this.fileData) {
      this.isLoading = false;
      return;
    }
    this.isLoading = true;

    const checkProductStatus = this.fileData
      .filter((product) => !!product.products)
      .map((product) => {
        const checkProduct = product.products?.find(
          (usedProduct) => usedProduct.productId === product.productId
        );
        if (!!checkProduct && Math.sign(checkProduct.redeemable) === -1) {
          return {
            ...product,
            productStatus: 'excluded'
          };
        } else {
          return {
            ...product
          };
        }
      }) as CheckProductStatus[];

    if (
      checkProductStatus.filter((product) => !!product.productStatus).length > 0
    ) {
      this.isLoading = false;
      this.$emit('watchInviteError');
      return;
    }
    const invitedProducts = this.fileData
      .map((product) => {
        if (product.productId === 0 && !!product.products) {
          return null;
        } else {
          return product;
        }
      })
      .filter((re) => !!re);

    await this.bulkInviteNewUserToProduct({
      invitations: invitedProducts as InviteUserToProductPayload[]
    });
    this.isLoading = false;
  }

  public handleEnableGenerateLink() {
    this.$emit('uploadBulkComplete');
  }

  public handleDisableGenerateLink() {
    this.$emit('disableGenerateLink');
  }
}
