/* tslint:disable */
import { ToastProgrammatic as Toast } from 'buefy';
import { Job } from 'bull';
import Vue from 'vue';
import { WorkerJobStatus } from '../jbi-shared/types/cplus-worker.types';
import { EVENTS } from '../store/modules/websocket/websocket.state';
import { useAction } from './store.util';
import { useWebsocket } from './websocket.util';

const displayToastError = () => {
  Toast.open({
    message: `Error processing. Please try again later.`,
    duration: 3000,
    type: 'is-danger',
  });
};

//This is for the 'this' attribute which is referring to the Vue
/* tslint:disable */
export const handleUserInviteListUploading = async function (
  this: Vue,
  job: Job,
) {
  if (!job) {
    displayToastError();
  }
  //here
  const {
    connectToWs,
    disconnectWs,
    joinRoom,
    listenOnceTo,
  } = useWebsocket.call(this);

  const { id: jobId } = job;

  const markWorkerJobAsCompleted: (payload: Job) => void = useAction.call(
    this,
    'websocket/markWorkerJobAsCompleted',
  );
  const markWorkerJobAsErrored: (payload: Job) => void = useAction.call(
    this,
    'websocket/markWorkerJobAsErrored',
  );

  await connectToWs();

  joinRoom(jobId);

  try {
    job = (await listenOnceTo(
      EVENTS.VALIDATE_IMPORTED_USER_INVITE_EVENT,
    )) as Job;
  } catch (error) {
    markWorkerJobAsErrored(job);
    displayToastError();
    await disconnectWs();
    return;
  }

  const { status, message, metadata, userList } = job.returnvalue;

  if (status === WorkerJobStatus.PROCESSED) {
    markWorkerJobAsCompleted(job);
    Toast.open({
      queue: true,
      position: 'is-top',
      message: `User list import success`,
    });

    return userList;
  } else if (status === WorkerJobStatus.FAILED) {
    markWorkerJobAsErrored(job);
    Toast.open({
      message,
      duration: 3000,
      type: 'is-danger',
    });
  } else {
    markWorkerJobAsErrored(job);
    displayToastError();
  }

  await disconnectWs();
  return;
};
