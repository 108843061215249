

























































































































import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import { State, Action } from 'vuex-class';
import { RootState } from '@/store/store';
import {
  GetInvitedUserPayload,
  FilteredUserPayload,
  User,
  Pagination,
  GetInvitedUserPayloadSort,
  UserTabFilterPayload
} from '@/store/modules/admin/types/admin.types';
import { isDifferent, isTruthy } from '@/jbi-shared/util/watcher.vue-decorator';
import { AxiosError } from 'axios';
import {
  get as _get,
  filter as _filter,
  keyBy as _keyBy,
  isEmpty as _isEmpty,
  forEach as _forEach
} from 'lodash';
import { ToastProgrammatic as Toast } from 'buefy';
import { ApiState } from '../../store/types/general.types';
import UserList from './components/UserList.vue';
import BasePaginatorHoc from '@/components/base/BasePaginatorHoc.vue';
import { Debounce } from '@/jbi-shared/util/debounce.vue-decorator';

const getInitFormParams = () => ({
  username: '',
  firstName: '',
  lastName: '',
  email: '',
  selectedUserType: 'all',
  selectedStatus: ''
});

@Component({
  components: {
    BasePaginatorHoc,
    UserList
  }
})
export default class UserTab extends Vue {
  public perPage = 50;
  public page = 1;
  public sortColumn: string = 'username';
  public sortOrder: 'ASC' | 'DESC' = 'ASC';
  public params: UserTabFilterPayload = getInitFormParams();

  @Action('admin/getUsers')
  public getUsers!: (params: GetInvitedUserPayload) => Promise<void>;

  @State(({ admin }: RootState) => admin.apiState.toggleUser)
  public toggleUser!: ApiState;

  @State(({ admin }: RootState) => admin.apiState.verifyUser)
  public verifyUser!: ApiState;

  @State(({ admin }: RootState) => admin.apiState.resendUserInvitation)
  public resendUserInvitation!: ApiState;

  @State(({ admin }: RootState) => admin.userDetails)
  public userList!: Pagination<User>;

  @State(({ admin }: RootState) => admin.apiState.getUsers)
  public getUsersStatus!: ApiState;

  @State(({ sumari }: RootState) => sumari.apiState.covertToMyJbiUser)
  public covertToMyJbiUser!: ApiState;

  get users() {
    return {
      items: _get(this.userList, 'items', []),
      totalItems: _get(this.userList, 'totalItems', 0)
    };
  }

  get UserList() {
    return UserList;
  }

  get userTypeOptions() {
    return [
      { label: 'All User', value: 'all' },
      { label: 'External User (Wolters Kluwer)', value: 'external' },
      { label: 'Internal User', value: 'internal' }
    ];
  }

  get statusesOptions() {
    return [
      { label: 'All ', value: '' },
      { label: 'Active', value: 'active' },
      { label: 'Inactive', value: 'inactive' },
      { label: 'Pending', value: 'pending' }
    ];
  }

  public resetFilter() {
    this.params = getInitFormParams();
  }

  public async handleSort(data: GetInvitedUserPayloadSort) {
    this.sortColumn = data.sortColumn;
    this.sortOrder = data.sortOrder;

    const params = {
      username: (this.$route.query.username as string) || this.params.username,
      firstName:
        (this.$route.query.firstName as string) || this.params.firstName,
      lastName: (this.$route.query.lastName as string) || this.params.lastName,
      email: (this.$route.query.email as string) || this.params.email,
      selectedUserType:
        (this.$route.query.selectedUserType as string) ||
        this.params.selectedUserType,
      selectedStatus:
        (this.$route.query.selectedStatus as string) ||
        this.params.selectedStatus,
      limit: +this.$route.query.limit || this.perPage,
      page: +this.$route.query.page || this.page,
      sortColumn: this.sortColumn,
      sortOrder: this.sortOrder
    };
    await this.getUsers(params);

    this.$router.push({
      path: this.$route.path,
      query: {
        tab: 'Users',
        username: this.$route.query.username || this.params.username,
        firstName: this.$route.query.firstName || this.params.firstName,
        lastName: this.$route.query.lastName || this.params.lastName,
        email: this.$route.query.email || this.params.email,
        selectedUserType:
          this.$route.query.selectedUserType || this.params.selectedUserType,
        selectedStatus:
          this.$route.query.selectedStatus || this.params.selectedStatus,
        limit: (this.$route.query.limit as string) || this.perPage.toString(),
        page: (this.$route.query.page as string) || this.page.toString(),
        sortColumn: this.sortColumn,
        sortOrder: this.sortOrder
      }
    });
  }

  public async handlePaginator({
    perPage,
    page
  }: {
    perPage: number;
    page: number;
  }) {
    if (this.$route.query.tab !== 'Users') {
      return;
    }
    this.perPage = perPage || +this.$route.query.limit;
    this.page = page || +this.$route.query.page;

    const params = {
      username: (this.$route.query.username as string) || this.params.username,
      firstName:
        (this.$route.query.firstName as string) || this.params.firstName,
      lastName: (this.$route.query.lastName as string) || this.params.lastName,
      email: (this.$route.query.email as string) || this.params.email,
      selectedUserType:
        (this.$route.query.selectedUserType as string) ||
        this.params.selectedUserType,
      selectedStatus:
        (this.$route.query.selectedStatus as string) ||
        this.params.selectedStatus,
      limit: this.perPage,
      page: this.page,
      sortColumn: (this.$route.query.sortColumn as string) || this.sortColumn,
      sortOrder:
        (this.$route.query.sortOrder as 'ASC' | 'DESC') || this.sortOrder
    };

    await this.getUsers(params);

    this.$router.push({
      path: this.$route.path,
      query: {
        tab: 'Users',
        username: this.$route.query.username || this.params.username,
        firstName: this.$route.query.firstName || this.params.firstName,
        lastName: this.$route.query.lastName || this.params.lastName,
        email: this.$route.query.email || this.params.email,
        selectedUserType:
          this.$route.query.selectedUserType || this.params.selectedUserType,
        selectedStatus:
          this.$route.query.selectedStatus || this.params.selectedStatus,
        limit: this.perPage.toString(),
        page: this.page.toString(),
        sortColumn: (this.$route.query.sortColumn as string) || this.sortColumn,
        sortOrder:
          (this.$route.query.sortOrder as 'ASC' | 'DESC') || this.sortOrder
      }
    });
  }

  public async handleFilter() {
    this.perPage = +this.$route.query.limit;
    const queryFilter: UserTabFilterPayload = {
      username: this.$route.query.username as string,
      firstName: this.$route.query.firstName as string,
      lastName: this.$route.query.lastName as string,
      email: this.$route.query.email as string,
      selectedUserType: (this.$route.query.selectedUserType as string) || 'all',
      selectedStatus: this.$route.query.selectedStatus as string
    };

    if (JSON.stringify(queryFilter) !== JSON.stringify(this.params)) {
      this.page = 1;
      const params = {
        username: this.params.username,
        firstName: this.params.firstName,
        lastName: this.params.lastName,
        email: this.params.email,
        selectedStatus: this.params.selectedStatus,
        userType: this.params.selectedUserType,
        limit: +this.$route.query.limit || this.perPage,
        page: 1,
        sortColumn: (this.$route.query.sortColumn as string) || this.sortColumn,
        sortOrder:
          (this.$route.query.sortOrder as 'ASC' | 'DESC') || this.sortOrder
      };

      await this.getUsers(params);
      this.$router.push({
        path: this.$route.path,
        query: {
          tab: 'Users',
          username: this.params.username,
          firstName: this.params.firstName,
          lastName: this.params.lastName,
          email: this.params.email,
          selectedUserType: this.params.selectedUserType,
          selectedStatus: this.params.selectedStatus,
          limit: (this.$route.query.limit as string) || this.perPage.toString(),
          page: (this.$route.query.page as string) || this.page.toString(),
          sortColumn:
            (this.$route.query.sortColumn as string) || this.sortColumn,
          sortOrder:
            (this.$route.query.sortOrder as 'ASC' | 'DESC') || this.sortOrder
        }
      });
    }
  }
  public async mounted() {
    if (this.$route.query.tab === 'Users') {
      this.params = {
        username:
          (this.$route.query.username as string) || this.params.username,
        firstName:
          (this.$route.query.firstName as string) || this.params.firstName,
        lastName:
          (this.$route.query.lastName as string) || this.params.lastName,
        email: (this.$route.query.email as string) || this.params.email,
        selectedUserType:
          (this.$route.query.selectedUserType as string) ||
          this.params.selectedUserType,
        selectedStatus:
          (this.$route.query.selectedStatus as string) ||
          this.params.selectedStatus
      };

      this.$router.push({
        path: this.$route.path,
        query: {
          tab: 'Users',
          username: this.$route.query.username || this.params.username,
          firstName: this.$route.query.firstName || this.params.firstName,
          lastName: this.$route.query.lastName || this.params.lastName,
          email: this.$route.query.email || this.params.email,
          selectedUserType:
            this.$route.query.selectedUserType || this.params.selectedUserType,
          selectedStatus:
            this.$route.query.selectedStatus || this.params.selectedStatus,
          limit: (this.$route.query.limit as string) || this.perPage.toString(),
          page: (this.$route.query.page as string) || this.page.toString(),
          sortColumn:
            (this.$route.query.sortColumn as string) || this.sortColumn,
          sortOrder:
            (this.$route.query.sortOrder as 'ASC' | 'DESC') || this.sortOrder
        }
      });
      this.perPage = +this.$route.query.limit || this.perPage;
      this.page = +this.$route.query.page || this.page;
      this.handleResetQuery();
    }
  }

  public handleResetQuery() {
    const params = {
      username: this.$route.query.username as string,
      firstName: this.$route.query.firstName as string,
      lastName: this.$route.query.lastName as string,
      email: this.$route.query.email as string,
      selectedUserType: this.$route.query.selectedUserType as string,
      selectedStatus: this.$route.query.selectedStatus as string,
      limit: +this.$route.query.limit,
      page: +this.$route.query.page,
      sortColumn: (this.$route.query.sortColumn as string) || this.sortColumn,
      sortOrder:
        (this.$route.query.sortOrder as 'ASC' | 'DESC') || this.sortOrder
    };
    this.params = params;
  }
  public async handleTabResetQuery() {
    this.handleResetQuery();
  }

  @Watch('getUsersStatus.success')
  @Debounce(400)
  @isDifferent
  @isTruthy
  public getUsersSuccess() {
    this.$emit('activateTab');
  }

  @Watch('getUsersStatus.error')
  @isDifferent
  @isTruthy
  public getUsersError() {
    this.$emit('activateTab');
  }

  @Watch('$route.query.tab')
  public routeParam() {
    if (this.$route.query.tab === 'Users') {
      this.handleTabResetQuery();
    }
  }

  @Watch('params', { deep: true })
  @Debounce(500)
  public onFilterChanged() {
    this.handleFilter();
  }

  @Watch('toggleUser.success')
  @isDifferent
  @isTruthy
  public onInviteUserSuccess() {
    Toast.open({
      queue: true,
      type: 'is-dark',
      position: 'is-top',
      message: `User status successfully changed.`
    });
    this.handleResetQuery();
  }

  @Watch('verifyUser.success')
  @isDifferent
  @isTruthy
  public onVerifySuccess() {
    Toast.open({
      queue: true,
      type: 'is-dark',
      position: 'is-top',
      message: `Email verification status successfully changed.`
    });
    this.handleResetQuery();
  }

  @Watch('resendUserInvitation.success')
  @isDifferent
  @isTruthy
  public onResendSuccess() {
    Toast.open({
      queue: true,
      type: 'is-dark',
      position: 'is-top',
      message: `Resend User Invitation Successfully`
    });
    this.handleResetQuery();
  }

  @Watch('resendUserInvitation.error')
  @isDifferent
  @isTruthy
  public onResendError(error: AxiosError) {
    Toast.open({
      queue: true,
      type: 'is-danger',
      position: 'is-top',
      message: `Error Resend User Invitation. Error: ${_get(
        error.response,
        'data.message'
      )}`
    });
  }

  @Watch('covertToMyJbiUser.success')
  @isDifferent
  @isTruthy
  public onConvertSuccess(error: AxiosError) {
    Toast.open({
      queue: true,
      type: 'is-dark',
      position: 'is-top',
      message: `Convert User to Internal Successfully`
    });
    this.handleResetQuery();
  }

  @Watch('covertToMyJbiUser.error')
  @isDifferent
  @isTruthy
  public onConvertError(error: AxiosError) {
    Toast.open({
      queue: true,
      type: 'is-danger',
      position: 'is-top',
      message: `Error Convert User to Internal. Error: ${_get(
        error.response,
        'data.message'
      )}`
    });
  }
}
