
















































import { Component, Vue, Watch, Prop } from 'vue-property-decorator';
import { ValidationProvider } from 'vee-validate';
import {
  ProductFormValues,
  SubscriptionForm
} from '@/store/modules/application/types/application.types';
import { Subscription } from 'rxjs';

const initialFormValue: SubscriptionForm = {
  exclusionDuration: 1,
  exclusionDurationType: 'MONTHS'
};

@Component({ components: { ValidationProvider } })
export default class EditSubscriptionForm extends Vue {
  public form: SubscriptionForm = {
    ...initialFormValue
  };
  @Prop() public subscription!: Subscription;

  get durationTypes() {
    return ['MONTHS', 'YEARS', 'DAYS'];
  }
  public closeModal() {
    this.$emit('closeModal');
  }

  public handleSubmit() {
    return this.$emit('submit', this.subscription, this.form);
  }
}
