var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('BaseTable',{scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('tr',[_c('th',{class:_vm.$style.tableHeader}),_vm._l((_vm.sortHeaders),function(item){return _c('SortableTableHeader',{key:item.placeholder,attrs:{"column":item.sortColumn,"sortOrder":_vm.getSortOrderOfColumn(item.sortColumn),"showArrow":item.sort && true,"sort":item.sort},on:{"sort":_vm.onClickHeaderColumn}},[_vm._v(" "+_vm._s(item.placeholder)+" ")])})],2)]},proxy:true},(_vm.getUserSubscriptionsSuccess)?{key:"body",fn:function(){return _vm._l((_vm.items),function(subscription){return _c('tr',{key:subscription.id},[_c('td',[(
            _vm.isInternalStatus(subscription) && _vm.isIndividualSub(subscription)
          )?_c('EditSubscriptionsButton',{attrs:{"data-test":"edit-subscription-modal","subscription":subscription},on:{"handleResetQuery":_vm.handleResetQuery,"toggle":function (subscription) { return _vm.handleToggleSubscription(subscription); }}}):_vm._e()],1),_c('td',[_vm._v(_vm._s(subscription.email))]),_c('td',[_vm._v(_vm._s(subscription.username))]),_c('td',[_vm._v(" "+_vm._s(subscription.applicationId.toUpperCase())+" ")]),_c('td',[_vm._v(_vm._s(subscription.productName))]),_c('td',[_vm._v(" "+_vm._s(_vm.dayjs(subscription.startedAt).format('DD MMM YYYY'))+" - "+_vm._s(_vm.dayjs(subscription.endedAt).format('DD MMM YYYY'))+" ")]),_c('td',[_vm._v(_vm._s(_vm.capitalize(subscription.type)))]),_c('td',[_c('router-link',{attrs:{"to":{
            name: 'admin-view-group',
            params: {
              groupId: String(subscription.groupId)
            }
          }}},[_vm._v(_vm._s(subscription.groupName))])],1),_c('td',[_vm._v(_vm._s(_vm.getSubscriptionStatus(subscription)))])])})},proxy:true}:{key:"body",fn:function(){return [_c('tr',[_c('td',{staticClass:"is-paddingless",attrs:{"colspan":"100%"}},[_c('BaseLoading')],1)])]},proxy:true},{key:"footer",fn:function(){return [_c('tr',[(_vm.getUserSubscriptionsSuccess)?_c('td',{staticClass:"is-paddingless",attrs:{"colspan":"100%"}},[(_vm.items && _vm.items.length > 0)?_c('BasePagination',{attrs:{"page":_vm.page,"perPage":_vm.perPage,"totalNumberOfPage":_vm.totalNumberOfPage,"totalCount":_vm.totalCount,"isFirstPage":_vm.isFirstPage,"isLastPage":_vm.isLastPage,"startItemIndex":_vm.startItemIndex,"endItemIndex":_vm.endItemIndex},on:{"update:page":function($event){_vm.page=$event},"update:perPage":function($event){_vm.perPage=$event},"update:per-page":function($event){_vm.perPage=$event}}}):_c('p',{staticClass:"p-2 has-text-centered"},[_vm._v("No subscription found")])],1):_vm._e()])]},proxy:true}],null,true)})}
var staticRenderFns = []

export { render, staticRenderFns }