























































import { Component, Vue, Watch, Prop } from 'vue-property-decorator';
import { isDifferent, isTruthy } from '@/jbi-shared/util/watcher.vue-decorator';
import { RootState } from '@/store/store';
import { Action, State } from 'vuex-class';
import { Product } from '@/store/modules/application/types/application.types';
import { InviteUserToProductPayload } from '@/store/modules/admin/types/admin.types';
import { ValidationProvider, ValidationObserver } from 'vee-validate';

@Component({
  components: { ValidationProvider, ValidationObserver }
})
export default class ReInviteForm extends Vue {
  @Prop() public products!: Product[];
  @Prop() public email!: string;
  @Prop() public firstName!: string;
  @Prop() public lastName!: string;

  public defaultProductOptions = {
    id: 0,
    name: 'Select a product'
  };

  public productId: number | null = null;

  @State((state: RootState) => state.admin.apiState.inviteNewUserToProduct)
  public inviteNewUserToProductState!: boolean;

  @Action('admin/inviteNewUserToProduct')
  public inviteNewUserToProduct!: (payload: InviteUserToProductPayload) => void;

  public handleInviteUser() {
    this.inviteNewUserToProduct({
      email: this.email,
      firstName: this.firstName,
      lastName: this.lastName,
      productId: this.productId as number
    });
  }

  @Watch('inviteNewUserToProductState.success')
  @isDifferent
  @isTruthy
  public watchInviteUserSuccess() {
    // @ts-ignore
    this.$parent.close();
  }
}
