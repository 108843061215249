





























































































































































import Container from '@/components/Container.vue';
import { isDifferent, isTruthy } from '@/jbi-shared/util/watcher.vue-decorator';
import BaseModal from '@/jbi-shared/vue-components/BaseModal.vue';
import { GetGroupsProductResponsePayload } from '@/store/modules/admin/types/group-product.types';
import { Product } from '@/store/modules/application/types/application.types';
import { RootState } from '@/store/store';
import { ApiState } from '@/store/types/general.types';
import InvitationTab from '@/views/AdminUserManagement/Invitation.tab.vue';
import SubscriptionTab from '@/views/AdminUserManagement/Subscription.tab.vue';
import UserTab from '@/views/AdminUserManagement/User.tab.vue';
import { InviteUserButton } from '@/views/AdminUserManagement/components';
import { InvitationListUploader } from '@/views/AdminUserManagement/components/invitations';
import GenerateInvitationForm from '@/views/AdminUserManagement/components/invitations/GenerateInvitationForm.vue';
import { AxiosError } from 'axios';
import { ToastProgrammatic as Toast } from 'buefy';
import { get as _get } from 'lodash';
import { IPaginationOptions } from 'nestjs-typeorm-paginate';
import { Component, Vue, Watch } from 'vue-property-decorator';
import { Action, State } from 'vuex-class';
import { isUserAllowed } from '@/utils/rbac.util';
import { PermissionsMatrixActionsEnum } from '@/store/modules/roles-and-permissions/types/roles-and-permissions.types';

let tabs: Array<Record<string, unknown>> = [
  {
    tab: 'Users',
    username: '',
    firstName: '',
    lastName: '',
    email: '',
    selectedUserType: 'all',
    selectedStatus: '',
    limit: '50',
    page: '1',
    sortColumn: 'username',
    sortOrder: 'ASC'
  },
  {
    tab: 'Invitations',
    email: '',
    latestActivity: '',
    applicationId: '0',
    invitationStatus: '',
    limit: '50',
    page: '1',
    sortColumn: 'email',
    sortOrder: 'ASC',
    username: ''
  },
  {
    tab: 'Subscriptions',
    email: '',
    username: '',
    productId: '0',
    groupProductId: '0',
    applicationId: '0',
    subscriptionStatus: '',
    type: '',
    limit: '50',
    page: '1',
    sortColumn: 'email',
    sortOrder: 'ASC'
  }
];

let tabMapModule: Array<{
  tab: string;
  action: PermissionsMatrixActionsEnum;
  module: string;
}> = [
  {
    tab: 'Users',
    action: PermissionsMatrixActionsEnum.READ,
    module: 'user_administration-users-read_all_users-read_user_list'
  },
  {
    tab: 'Invitations',
    action: PermissionsMatrixActionsEnum.READ,
    module: 'user_administration-users-read_all_users-read_user_invitations'
  },
  {
    tab: 'Subscriptions',
    action: PermissionsMatrixActionsEnum.READ,
    module: 'user_administration-subscriptions'
  }
];

let tabsUserHasAccessTo: Array<{
  tab: string;
  action: PermissionsMatrixActionsEnum;
  module: string;
}> = [];

@Component({
  computed: {
    PermissionsMatrixActionsEnum() {
      return PermissionsMatrixActionsEnum;
    }
  },
  methods: { isUserAllowed },
  components: {
    BaseModal,
    InviteUserButton,
    Container,
    InvitationListUploader,
    GenerateInvitationForm,
    UserTab,
    InvitationTab,
    SubscriptionTab
  }
})
export default class AdminUserManagement extends Vue {
  $refs!: {
    GenerateInvitationForm: GenerateInvitationForm;
    GenerateBulkInvitationForm: InvitationListUploader;
  };

  public activeTab = 0;
  public disableTab = true;
  public buttonDisabled = true;
  public buttonInviteDisabled = true;

  @Action('application/getApplications')
  public getApplications!: () => Promise<void>;

  @Action('admin/getGroupProducts')
  getGroupProducts!: (
    options: IPaginationOptions
  ) => Promise<GetGroupsProductResponsePayload>;

  @State((state: RootState) => state.admin.apiState.bulkInviteNewUserToProduct)
  public bulkInvite!: ApiState;

  @State((state: RootState) => state.admin.apiState.generateInvitationLinks)
  public generateInvitationLinks!: ApiState;

  @State(({ admin }: RootState) => admin.apiState.inviteNewUserToProduct)
  public inviteNewUserToProduct!: ApiState;

  get products(): Product[] {
    const products =
      (this.$store.state as RootState).application.productApplications
        ?.products || [];
    return products.filter((p) => p.isActive && !p.isDeleted);
  }
  public handleBulkGenerateButton() {
    this.buttonInviteDisabled = false;
  }

  public resetInviteButtonState() {
    this.buttonInviteDisabled = true;
  }

  public handleGenerateButton() {
    this.buttonDisabled = false;
  }

  public resetButtonState() {
    this.buttonDisabled = true;
  }

  isGenerateInvitationFormValid(isValid: boolean) {
    this.buttonDisabled = !isValid;
  }

  isInvitationUserListValid(isValid: boolean) {
    this.buttonInviteDisabled = !isValid;
  }

  filterTabsBasedOnAvailability() {
    for (const tab of tabMapModule) {
      if (
        !isUserAllowed(
          tab.action,
          tab.module,
          undefined,
          undefined,
          undefined,
          undefined,
          true
        )
      ) {
        tabMapModule = tabMapModule.filter((item) => item.tab !== tab.tab);
        tabs = tabs.filter((item) => item.tab !== tab.tab);
      }
    }

    tabsUserHasAccessTo = tabMapModule;
  }

  /**
   * With the introduction of permissions matrix, a user can be restricted
   * from accessing certain tabs. However, the value for tab that is set in
   * router.ts for 'admin-user-management' is 'Users'. This means that if the
   * user is on the 'Users' tab and their access changes while they're using
   * the system, the query params remain incorrect. This function rectifies that
   * by looking for the nearest tab the user has access to and calling the
   * onTabChange function with the correct index value (as opposed to 0 which
   * was the case before).
   */
  getNextAccessibleTabIndex(): number {
    const targetTab = tabMapModule.find(
      (item) => item.tab === this.$route.query.tab
    );
    if (
      targetTab &&
      isUserAllowed(
        targetTab.action,
        targetTab.module,
        undefined,
        undefined,
        undefined,
        undefined,
        true
      )
    ) {
      return tabs.findIndex((res) => res.tab === this.$route.query.tab);
    } else {
      for (const tab of tabsUserHasAccessTo) {
        if (
          isUserAllowed(
            tab.action,
            tab.module,
            undefined,
            undefined,
            undefined,
            undefined,
            true
          )
        ) {
          const index = tabsUserHasAccessTo.findIndex(
            (res) => res.tab === tab.tab
          );
          this.onTabChange(index);
          return index;
        }
      }
    }

    return 0;
  }

  public mounted() {
    this.filterTabsBasedOnAvailability();

    if (this.$route.query.tab) {
      const index = this.getNextAccessibleTabIndex();
      if (index >= 0) {
        this.activeTab = +index;
        this.disableTab = false;
      }
    }

    this.getApplications();

    if (
      isUserAllowed(
        PermissionsMatrixActionsEnum.READ,
        'group_administration-products-read_products'
      )
    ) {
      this.getGroupProducts({
        page: 1,
        limit: Math.pow(10, 3)
      });
    }
  }

  public watchGenerateInviteError() {
    Toast.open({
      queue: true,
      type: 'is-danger',
      position: 'is-top',
      message: `Some users are currently under exclusion period`
    });
  }

  public watchInviteError() {
    Toast.open({
      queue: true,
      type: 'is-danger',
      position: 'is-top',
      message: `Some users are currently under exclusion period`
    });
  }

  public activateTab() {
    this.disableTab = false;
  }

  @Watch('bulkInvite.success')
  @Watch('inviteNewUserToProduct.success')
  @Watch('generateInvitationLinks.success')
  @isDifferent
  @isTruthy
  public async watchBulkInviteSuccess(value: File[]) {
    Toast.open({
      queue: true,
      type: 'is-success',
      position: 'is-top',
      message: `User(s) successfully invited!`
    });
  }

  @Watch('inviteNewUserToProduct.error')
  @isDifferent
  @isTruthy
  public watchInviteUserError(error: AxiosError) {
    Toast.open({
      queue: true,
      type: 'is-danger',
      position: 'is-top',
      message: `Error Create User Invitation. Error: ${_get(
        error.response,
        'data.message'
      )}`
    });
  }

  @Watch('activeTab')
  public onTabChange(value: number) {
    this.disableTab = true;
    if (this.$route.query.tab === tabs[value].tab) {
      return;
    }

    const newQueryWhenTabChange = {
      ...tabs[value],
      /** Persist email and username query param on tab change */
      email: this.$route.query.email || '',
      username: this.$route.query.username || ''
    };
    this.$router.replace({ query: undefined });

    this.$router.push({
      query: {
        ...newQueryWhenTabChange
      }
    });
  }
}
