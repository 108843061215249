import {
  generateJoinInvitationLink,
  generateProductInvitationLink
} from '@/jbi-shared/util/jaas-invitation-link-generator';
import { Invitation } from '@/store/modules/admin/types/admin.types';
import { ToastProgrammatic as Toast } from 'buefy';
import Vue from 'vue';
import Component from 'vue-class-component';
import { Action } from 'vuex-class';

@Component({})
export class InvitationAction extends Vue {
  @Action('admin/resendUserInvitation')
  private resendUserInvitation!: (
    params: Pick<Invitation, 'id' | 'email'>
  ) => Promise<void>;

  @Action('admin/removeInvitation')
  private removeInvitation!: (id: number) => Promise<void>;

  @Action('admin/removeUserInvitation')
  private removeUserInvitation!: (id: number) => Promise<void>;

  public handleResendInvite({ id, email }: Invitation) {
    if (
      confirm(
        `Are you sure that you want to resend invitation for this participant?`
      )
    ) {
      this.resendUserInvitation({ id, email });
    }
  }

  public async handleCopyInvite(payload: Invitation) {
    const inviteUrl = this.generateUrl(payload);

    await this.$copyText(inviteUrl)
      .then((e) => {
        this.onCopySuccess();
      })
      .catch((e) => {
        this.onCopyError();
      });
  }

  // for refetech all invitations
  public async handleRemoveInvite({ id }: Invitation) {
    if (confirm(`Are you sure you want to remove this invitation?`)) {
      await this.removeInvitation(id);
    }
  }

  // for refetch user invitations, cause remove user invitation state is isolate
  public async handleRemoveUserInvite({ id }: Invitation) {
    if (confirm(`Are you sure you want to remove this invitation?`)) {
      await this.removeUserInvitation(id);
    }
  }

  private generateUrl(payload: Invitation) {
    const {
      applicationProductId: productId,
      email,
      token,
      applicationProduct
    } = payload;

    const applicationId = applicationProduct
      ? applicationProduct.applicationId
      : undefined;

    const baseUrl = window.location.protocol + '//' + window.location.host;

    if (productId && applicationId) {
      return generateProductInvitationLink({
        dashboardUrl: baseUrl,
        email,
        token,
        productId,
        applicationId
      });
    }

    return generateJoinInvitationLink({ dashboardUrl: baseUrl, token, email });
  }

  private onCopySuccess() {
    Toast.open({
      message: `Link copied successfully`,
      position: 'is-top',
      type: 'is-dark'
    });
  }

  private onCopyError() {
    Toast.open({
      queue: true,
      type: 'is-danger',
      position: 'is-top',
      message: `Error copying link. Please try again later.`
    });
  }
}
