






















































































import { Component, Vue, Watch, Prop } from 'vue-property-decorator';
import { ValidationProvider } from 'vee-validate';
import {
  isDifferent,
  isDifferentDeep,
  isTruthy
} from '@/jbi-shared/util/watcher.vue-decorator';
import { RootState } from '@/store/store';
import { Action, State } from 'vuex-class';
import { AxiosError } from 'axios';
import { get as _get } from 'lodash';
import { Product } from '@/store/modules/application/types/application.types';
import { isEmail } from '@/jbi-shared/util/validate-email-domains.util';

export interface InviteUserFormValues {
  email?: string;
  username?: string;
  firstName?: string;
  lastName?: string;
  productId: number;
}

const initialFormValue = {
  email: '',
  username: '',
  firstName: '',
  lastName: '',
  productId: 0
};

@Component({
  components: {
    ValidationProvider
  }
})
export default class InviteUserForm extends Vue {
  @Prop()
  public products!: Product[];

  public defaultProductOptions = {
    id: 0,
    name: 'Select a product'
  };

  public form: InviteUserFormValues = {
    ...initialFormValue
  };

  @State(
    (state: RootState) => state.admin.apiState.inviteNewUserToProduct.success
  )
  public inviteNewUserToProductSuccess!: boolean;

  @Watch('inviteNewUserToProductSuccess')
  @isDifferent
  @isTruthy
  public watchInviteUserSuccess() {
    this.$emit('closeModal');
  }

  public handleSubmit() {
    this.$emit('submit', this.form);
  }

  @Watch('form.firstName')
  @Watch('form.lastName')
  @Watch('form.email')
  public watchFormValues(formValues: InviteUserFormValues) {
    if (
      this.form.email &&
      isEmail(this.form.email) &&
      this.form.firstName &&
      this.form.lastName
    ) {
      this.$emit('changeDisabledButton', false);
    } else {
      this.$emit('changeDisabledButton', true);
    }
  }
}
