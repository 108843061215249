




























































































































































import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import { State, Action } from 'vuex-class';
import { Pagination } from '@/store/modules/admin/types/admin.types';
import {
  Subscription,
  GetSubscriptionsPayload,
  GetSubscriptionsPayloadSort,
  GetSubscriptionsFilterPayload
} from '@/store/modules/admin/types/user-subscription.types';
import { User } from '@/store/modules/subscription/subscription.state';
import { RootState } from '@/store/store';
import SubscriptionList from './components/SubscriptionList.vue';
import BasePaginatorHoc from '@/components/base/BasePaginatorHoc.vue';
import { Debounce } from '../../jbi-shared/util/debounce.vue-decorator';
import { get as _get } from 'lodash';
import {
  Application,
  Product
} from '../../store/modules/application/types/application.types';
import { UpdateUserSubscriptionStatusPayload } from '../../store/modules/admin/types/user-subscription.types';
import { ApiState } from '../../store/types/general.types';
import {
  ToastProgrammatic as Toast,
  DialogProgrammatic as Dialog
} from 'buefy';
import { SubscriptionStatus } from '../../jbi-shared/types/jaas-oauth2.types';
import { AxiosError } from 'axios';
import { isDifferent, isTruthy } from '@/jbi-shared/util/watcher.vue-decorator';

const getInitFormParams = () => ({
  email: '',
  username: '',
  productId: 0,
  groupProductId: 0,
  applicationId: '0',
  subscriptionStatus: '',
  type: ''
});

const sortColumnList = () => [
  'groupName',
  'subscriptionType',
  'startSubscription',
  'productName',
  'applicationId',
  'productName',
  'type'
];

@Component({
  components: {
    BasePaginatorHoc,
    SubscriptionList
  }
})
export default class SubscriptionTab extends Vue {
  public perPage = 50;
  public page = 1;
  public sortColumn: string = 'email';
  public sortOrder: 'ASC' | 'DESC' = 'ASC';
  public params = getInitFormParams();

  @Action('admin/updateSubscriptionStatus')
  public updateSubscriptionStatus!: (
    params: UpdateUserSubscriptionStatusPayload
  ) => Promise<void>;

  @Action('admin/getSubscriptions')
  public getSubscriptions!: (
    params: Partial<GetSubscriptionsPayload>
  ) => Promise<void>;

  @State(({ admin }: RootState) => admin.apiState.getSubscriptions)
  public getSubscriptionsStatus!: ApiState;

  @State((state: RootState) => state.application.productApplications?.products)
  public products!: Product[];

  @State(
    ({ application }: RootState) =>
      application.productApplications?.applications
  )
  private applications!: Application[];

  @State(({ admin }: RootState) => admin.subscriptions)
  private subscriptions!: Pagination<Subscription[]>;

  @State((state: RootState) => state.admin.apiState.updateSubscriptionStatus)
  private updateSubscriptionStatusState!: ApiState;

  get applicationOptions() {
    if (this.applications) {
      return [
        { id: 0, name: 'All Applications' },
        ...this.applications.map(({ id, name }) => ({
          id,
          name
        }))
      ];
    }
    return [];
  }

  get groupProductsOptions() {
    return (
      (this.$store
        .state as RootState).admin.groupProducts?.items?.map(
        ({ id, name }) => ({ id: `group_${id}`, name })
      ) || []
    );
  }

  get productsOptions() {
    return this.products?.map(({ id, name }) => ({
      id: `individual_${id}`,
      name
    }));
  }

  get statusesOptions() {
    return [
      { label: 'All Statuses', value: '' },
      { label: 'Blocked', value: 'Blocked' },
      { label: 'Active', value: 'Active' },
      { label: 'Expired', value: 'Expired' }
    ];
  }

  get typeOptions() {
    return [
      { label: 'All Types', value: '' },
      { label: 'Group', value: 'group' },
      { label: 'Individual', value: 'individual' }
    ];
  }

  get SubscriptionList() {
    return SubscriptionList;
  }

  public resetFilter() {
    this.params = getInitFormParams();
  }

  public handleSubscriptionStatus({
    subscription,
    status
  }: {
    subscription: Subscription;
    status: SubscriptionStatus;
  }) {
    const { userApplicationId, email } = subscription;

    let text = '';

    if (status === SubscriptionStatus.BLOCKED) {
      text = 'Disable';
    } else if (status === SubscriptionStatus.ACTIVE) {
      text = 'Enable';
    }

    return Dialog.confirm({
      message: `<p style="width:437px;word-wrap: break-word;font-size: 20px; font-weight: 700; padding-bottom: 20px;">${text} ${email} Subscription</p><p style="width:437px;word-wrap: break-word;">Are you sure you want to ${text.toLowerCase()} <b>${email}</b>'s subscription?</p>`,
      confirmText: text,
      type: 'is-primary',
      onConfirm: async () => {
        await this.updateSubscriptionStatus({
          id: userApplicationId!,
          status
        });
      }
    });
  }

  public async handleSort(data: GetSubscriptionsPayloadSort) {
    this.sortColumn = data.sortColumn;
    this.sortOrder = data.sortOrder;

    const params = {
      email: (this.$route.query.email as string) || this.params.email,
      username: (this.$route.query.username as string) || this.params.username,
      productId: +this.$route.query.productId || this.params.productId,
      groupProductId:
        +this.$route.query.groupProductId || this.params.groupProductId,
      applicationId:
        String(this.$route.query.applicationId) || this.params.applicationId,
      status:
        (this.$route.query.subscriptionStatus as string) ||
        this.params.subscriptionStatus,
      type: (this.$route.query.type as string) || this.params.type,
      limit: +this.$route.query.limit || this.perPage,
      page: +this.$route.query.page || this.page,
      sortColumn: this.sortColumn,
      sortOrder: this.sortOrder
    };

    await this.getSubscriptions(params);

    this.$router.push({
      path: this.$route.path,
      query: {
        tab: 'Subscriptions',
        email: this.$route.query.email || this.params.email,
        username: this.$route.query.username || this.params.username,
        productId:
          this.$route.query.productId || this.params.productId.toString(),
        groupProductId:
          (this.$route.query.groupProductId as string) ||
          this.params.groupProductId.toString(),
        applicationId:
          this.$route.query.applicationId ||
          this.params.applicationId.toString(),
        subscriptionStatus:
          this.$route.query.subscriptionStatus ||
          this.params.subscriptionStatus,
        type: this.$route.query.type || this.params.type,
        limit: (this.$route.query.limit as string) || this.perPage.toString(),
        page: (this.$route.query.page as string) || this.page.toString(),
        sortColumn: this.sortColumn,
        sortOrder: this.sortOrder
      }
    });
  }

  public async handlePaginator({
    perPage,
    page
  }: {
    perPage: number;
    page: number;
  }) {
    if (this.$route.query.tab !== 'Subscriptions') {
      return;
    }
    this.perPage = perPage || +this.$route.query.limit;
    this.page = page || +this.$route.query.page;

    const params = {
      email: (this.$route.query.email as string) || this.params.email,
      username: (this.$route.query.username as string) || this.params.username,
      productId: +this.$route.query.productId || this.params.productId,
      groupProductId:
        +this.$route.query.groupProductId || this.params.groupProductId,
      applicationId:
        String(this.$route.query.applicationId) || this.params.applicationId,
      status:
        (this.$route.query.subscriptionStatus as string) ||
        this.params.subscriptionStatus,
      type: (this.$route.query.type as string) || this.params.type,
      limit: this.perPage,
      page: this.page,
      sortColumn: sortColumnList().includes(
        this.$route.query.sortColumn as string
      )
        ? (this.$route.query.sortColumn as string) || this.sortColumn
        : this.sortColumn,
      sortOrder:
        (this.$route.query.sortOrder as 'ASC' | 'DESC') || this.sortOrder
    };

    await this.getSubscriptions(params);

    this.$router.push({
      path: this.$route.path,
      query: {
        tab: 'Subscriptions',
        email: this.$route.query.email || this.params.email,
        username: this.$route.query.username || this.params.username,
        productId:
          this.$route.query.productId || this.params.productId.toString(),
        groupProductId:
          (this.$route.query.groupProductId as string) ||
          this.params.groupProductId.toString(),
        applicationId:
          this.$route.query.applicationId ||
          this.params.applicationId.toString(),
        subscriptionStatus:
          this.$route.query.subscriptionStatus ||
          this.params.subscriptionStatus,
        type: this.$route.query.type || this.params.type,
        limit: this.perPage.toString(),
        page: this.page.toString(),
        sortColumn: (this.$route.query.sortColumn as string) || this.sortColumn,
        sortOrder:
          (this.$route.query.sortOrder as 'ASC' | 'DESC') || this.sortOrder
      }
    });
  }

  public async handleFilter() {
    this.perPage = +this.$route.query.limit;
    const queryFilter: GetSubscriptionsFilterPayload = {
      email: this.$route.query.email as string,
      username: this.$route.query.username as string,
      productId: +this.$route.query.productId,
      groupProductId: +this.$route.query.groupProductId,
      applicationId: String(this.$route.query.applicationId),
      subscriptionStatus: this.$route.query.subscriptionStatus as string,
      type: this.$route.query.type as string
    };

    if (JSON.stringify(queryFilter) !== JSON.stringify(this.params)) {
      this.page = 1;
      const params = {
        email: this.params.email,
        username: this.params.username,
        groupProductId: this.params.groupProductId,
        productId: this.params.productId,
        applicationId: this.params.applicationId,
        status: this.params.subscriptionStatus,
        type: this.params.type,
        limit: +this.$route.query.limit || this.perPage,
        page: 1,
        sortColumn: (this.$route.query.sortColumn as string) || this.sortColumn,
        sortOrder:
          (this.$route.query.sortOrder as 'ASC' | 'DESC') || this.sortOrder
      };

      await this.getSubscriptions(params);

      this.$router.push({
        path: this.$route.path,
        query: {
          tab: 'Subscriptions',
          email: this.params.email,
          username: this.params.username,
          productId: this.params.productId.toString(),
          groupProductId: this.params.groupProductId.toString(),
          applicationId: this.params.applicationId.toString(),
          subscriptionStatus: this.params.subscriptionStatus,
          type: this.params.type,
          limit: (this.$route.query.limit as string) || this.perPage.toString(),
          page: (this.$route.query.page as string) || this.page.toString(),
          sortColumn:
            (this.$route.query.sortColumn as string) || this.sortColumn,
          sortOrder:
            (this.$route.query.sortOrder as 'ASC' | 'DESC') || this.sortOrder
        }
      });
    }
  }

  public async handleResetQuery() {
    const params = {
      email: this.$route.query.email as string,
      username: this.$route.query.username as string,
      productId: +this.$route.query.productId,
      groupProductId: +this.$route.query.groupProductId,
      applicationId: String(this.$route.query.applicationId),
      status: this.$route.query.subscriptionStatus as string,
      type: this.$route.query.type as string,
      limit: +this.$route.query.limit,
      page: +this.$route.query.page,
      sortColumn: sortColumnList().includes(
        this.$route.query.sortColumn as string
      )
        ? (this.$route.query.sortColumn as string) || this.sortColumn
        : this.sortColumn,
      sortOrder:
        (this.$route.query.sortOrder as 'ASC' | 'DESC') || this.sortOrder
    };
    await this.getSubscriptions(params);
  }

  public mounted() {
    if (this.$route.query.tab === 'Subscriptions') {
      this.params = {
        email: (this.$route.query.email as string) || this.params.email,
        username:
          (this.$route.query.username as string) || this.params.username,
        productId: +this.$route.query.productId || this.params.productId,
        groupProductId:
          +this.$route.query.groupProductId || this.params.groupProductId,
        applicationId:
          String(this.$route.query.applicationId) || this.params.applicationId,
        subscriptionStatus:
          (this.$route.query.subscriptionStatus as string) ||
          this.params.subscriptionStatus,
        type: (this.$route.query.type as string) || this.params.type
      };

      this.$router.push({
        path: this.$route.path,
        query: {
          tab: 'Subscriptions',
          email: this.$route.query.email || this.params.email,
          username: this.$route.query.username || this.params.username,
          productId:
            this.$route.query.productId || this.params.productId.toString(),
          groupProductId:
            (this.$route.query.groupProductId as string) ||
            this.params.groupProductId.toString(),
          applicationId:
            this.$route.query.applicationId ||
            this.params.applicationId.toString(),
          subscriptionStatus:
            this.$route.query.subscriptionStatus ||
            this.params.subscriptionStatus,
          type: this.$route.query.type || this.params.type,
          limit:
            this.formatParam(this.$route.query.limit as string, '50', 150) ||
            this.perPage.toString(),
          page:
            this.formatParam(this.$route.query.page as string, '1') ||
            this.page.toString(),
          sortColumn: sortColumnList().includes(
            this.$route.query.sortColumn as string
          )
            ? (this.$route.query.sortColumn as string) || this.sortColumn
            : this.sortColumn,
          sortOrder:
            (this.$route.query.sortOrder as 'ASC' | 'DESC') || this.sortOrder
        }
      });

      this.perPage = +this.$route.query.limit || this.perPage;
      this.page = +this.$route.query.page || this.page;
      this.handleResetQuery();
    }
  }
  public async handleTabResetQuery() {
    this.params = {
      email: this.$route.query.email as string,
      username: this.$route.query.username as string,
      productId: +this.$route.query.productId,
      groupProductId: +this.$route.query.groupProductId,
      applicationId: String(this.$route.query.applicationId),
      subscriptionStatus: this.$route.query.subscriptionStatus as string,
      type: this.$route.query.type as string
    };
    await this.handleResetQuery();
  }

  private formatParam(
    value: string,
    defaultValue: string,
    ceiling?: number
  ): string {
    const numericValue = +value;

    if (isNaN(numericValue) || numericValue < 1) {
      return defaultValue;
    }

    if (ceiling && numericValue > ceiling) {
      return ceiling.toString();
    }

    return numericValue.toString();
  }

  @Watch('getSubscriptionsStatus.success')
  @Debounce(400)
  @isDifferent
  @isTruthy
  private getSubscriptionsStatusSuccess() {
    this.$emit('activateTab');
  }

  @Watch('getSubscriptionsStatus.error')
  @isDifferent
  @isTruthy
  private getSubscriptionsStatusError() {
    this.$emit('activateTab');
  }

  @Watch('$route.query.tab')
  private routeParam() {
    if (this.$route.query.tab === 'Subscriptions') {
      this.handleTabResetQuery();
    }
  }
  @Watch('params', { deep: true })
  @Debounce(500)
  private onFilterChanged() {
    this.handleFilter();
  }

  @Watch('updateSubscriptionStatusState.success')
  private onSubscriptionStatusUpdateSuccess() {
    this.handleResetQuery();
  }
  @Watch('extendProductSubscription.success')
  private onExtendSubscriptionUpdateSuccess() {
    this.handleResetQuery();
  }

  @Watch('updateSubscriptionStatusState.error')
  private onSubscriptionStatusUpdateError(error: AxiosError) {
    Toast.open({
      queue: true,
      type: 'is-danger',
      position: 'is-top',
      message: `Error Update User Subscription. Error: ${_get(
        error.response,
        'data.message'
      )}`
    });
  }
}
