














































































































































import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import { Action, State } from 'vuex-class';
import { Product } from '@/store/modules/application/types/application.types';
import { cloneDeep, differenceBy } from 'lodash';

import {
  ToastProgrammatic as Toast,
  DialogProgrammatic as Dialog
} from 'buefy';
import {
  InviteUserToProductPayload,
  DifferenceInviteUserToProductPayload
} from '@/store/modules/admin/types/admin.types';
import { Debounce } from '@/jbi-shared/util/debounce.vue-decorator';
import { RootState } from '../../../../store/store';
import { isEmail } from '../../../../jbi-shared/util/validate-email-domains.util';

export interface SelectedProduct {
  productId: number;
}

const initialFormValue = {
  productId: 0
};

@Component({})
export default class CsvUploadComplete extends Vue {
  @Prop(String) fileName!: string;
  @Prop(Array) userList!: [];
  @Prop() products!: Product[];

  public syncedUserList: InviteUserToProductPayload[] = [];
  public syncedOriginalUserList: InviteUserToProductPayload[] = [];

  public selectedProduct: SelectedProduct = {
    ...initialFormValue
  };

  public defaultProductOptions = {
    id: 0,
    name: 'Select a product'
  };

  public isLoading = false;
  public differenceUserEmail:
    | InviteUserToProductPayload
    | DifferenceInviteUserToProductPayload = {};

  @Action('admin/validateSingleUserProduct')
  public validateSingleUserProduct!: (
    data: InviteUserToProductPayload
  ) => Promise<any>;

  @State((state: RootState) => state.admin.validateSingleUserProduct)
  public validateSingleUserProductState!: InviteUserToProductPayload;

  public checkProducts(user: InviteUserToProductPayload) {
    if (!!user && !!user.products && user.productId) {
      const result = user.products.filter(
        (res) => res.productId === user.productId
      );
      if (result.length !== 0) {
        // no exclusion
        if (Math.sign(result[0].redeemable) === -1) {
          return true;
        } else {
          return false;
        }
      } else {
        return false;
      }
    }
  }

  public handleSelect(productId: number) {
    const result = this.syncedUserList.map((users: any) => {
      return {
        ...users,
        productId
      };
    });
    this.syncedUserList = result;
  }

  public onClickReupload() {
    this.$emit('isUserListValid', false);
    this.$emit('clear');
  }

  public removeImportedUser(key: number) {
    const msg = 'Are you sure you want to remove this imported user?';
    return Dialog.confirm({
      message: msg,
      confirmText: 'Confirm',
      type: 'is-primary',
      onConfirm: () => {
        this.syncedUserList.splice(key, 1);
      }
    });
  }

  public checkLoading(user: InviteUserToProductPayload) {
    const difference = differenceBy(
      this.syncedUserList,
      this.syncedOriginalUserList,
      'email'
    );

    if (
      difference.length > 0 &&
      difference[0].email === user.email &&
      this.isLoading
    ) {
      return true;
    }
    return false;
  }

  public isValid(attrValue: string, attr: string) {
    let isValid = true;
    let errorMessage = null;
    if (attrValue) {
      switch (attr) {
        case 'email':
          isValid = isEmail(attrValue) ? true : false;
          errorMessage = !isEmail(attrValue) ? 'Invalid Email' : null;
          break;
        default:
          isValid = true;
          errorMessage = null;
      }
    } else {
      isValid = false;
      errorMessage = 'This Field is Required';
    }

    return { isValid, errorMessage };
  }

  @Watch('syncedUserList', { deep: true })
  public async watchSyncedMembersData() {
    const difference = differenceBy(
      this.syncedUserList,
      this.syncedOriginalUserList,
      'email'
    );

    if (difference.length > 0 && !!difference[0].productId) {
      this.$emit('disableGenerateLink');
      this.differenceUserEmail = difference[0];
    }

    let isListValid = !this.syncedUserList.length ? false : true;
    for (const user of this.syncedUserList) {
      if (user.email && user.firstName && user.lastName) {
        if (!this.isValid(user.email, 'email').isValid) {
          isListValid = false;
          break;
        } else if (!this.isValid(user.firstName, 'firstName').isValid) {
          isListValid = false;
          break;
        } else if (!this.isValid(user.lastName, 'lastName').isValid) {
          isListValid = false;
          break;
        } else {
          isListValid = true;
        }
      } else {
        isListValid = false;
        break;
      }
    }
    this.$emit('isUserListValid', isListValid);
    this.$emit('update:userList', this.syncedUserList);
  }

  @Watch('differenceUserEmail', { deep: true })
  @Debounce(500)
  public async watchSyncedMembersDataEmail() {
    this.isLoading = true;
    this.validateSingleUserProduct(
      this.differenceUserEmail as InviteUserToProductPayload
    );
  }

  @Watch('membersData', { deep: true })
  public watchMembersData() {
    this.syncedUserList = cloneDeep(this.userList);
  }

  @Watch('validateSingleUserProductState')
  public watchValidateSingleUserProductState(
    value: InviteUserToProductPayload
  ) {
    const checker = this.syncedUserList.map((res) => {
      return res.email === this.differenceUserEmail.email ? value : res;
    });
    this.$emit('enableGenerateLink');
    this.isLoading = false;
    this.syncedUserList = cloneDeep(checker);
    this.syncedOriginalUserList = cloneDeep(checker);
    this.$emit('update:userList', this.syncedUserList);
  }

  public mounted() {
    this.syncedUserList = cloneDeep(this.userList);
    this.syncedOriginalUserList = cloneDeep(this.userList);
  }
}
