var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ValidationObserver',{scopedSlots:_vm._u([{key:"default",fn:function(validator){return [_c('form',{on:{"submit":function($event){$event.preventDefault();return (function () {
        validator.validate().then(function (validated) {
          if (validated === false) { return; }
          _vm.$refs.InviteUserForm.handleSubmit();
        });
      })($event)}}},[_c('BaseModal',{attrs:{"title":"Invite new user"},scopedSlots:_vm._u([{key:"openBtn",fn:function(ref){
      var openModal = ref.openModal;
return [_c('span',{class:['button', 'is-primary'],on:{"click":openModal}},[_vm._v(" Invite ")])]}},{key:"modalBody",fn:function(ref){
      var closeModal = ref.closeModal;
return [_c('InviteUserForm',{ref:"InviteUserForm",attrs:{"products":_vm.products},on:{"closeModal":closeModal,"submit":_vm.handleInviteUser,"changeDisabledButton":_vm.handleSubmitButtonDisabledChange}})]}},{key:"modalFoot",fn:function(ref){
      var closeModal = ref.closeModal;
return [_c('button',{staticClass:"button is-primary",class:{ 'is-loading': _vm.inviteNewUserToProductLoading },attrs:{"type":"submit","disabled":_vm.inviteNewUserSubmitButton,"data-test":"send-individual-invite"},on:{"closeModal":closeModal}},[_vm._v(" Invite ")])]}}],null,true)})],1)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }