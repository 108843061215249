























































































import { Component, Vue, Prop, Watch, Model } from 'vue-property-decorator';
import { State, Getter, Action, Mutation, namespace } from 'vuex-class';
import { mixins } from 'vue-class-component';
import BaseTable from '@/components/BaseTable.vue';
import BasePagination from '@/components/base/BasePagination.vue';
import BaseLoading from '@/components/base/BaseLoading.vue';
import { get as _get } from 'lodash';
import { Invitation } from '@/store/modules/admin/types/admin.types';
import dayjs from 'dayjs';
import moment from 'moment';
import { PaginationMixin } from '@/components/base/pagination.mixin';
import { InvitationAction } from '../mixins/invitation-action.mixin';
import { RootState } from '@/store/store';
import TableActionDropdown from '@/components/TableActionDropdown.vue';
import SortableTableHeader from '@/components/SortableTableHeader.vue';
import { InvitationType } from '../../../jbi-shared/types/jaas-invitation.types';
@Component({
  components: {
    BaseTable,
    BasePagination,
    BaseLoading,
    TableActionDropdown,
    SortableTableHeader
  }
})
export default class InvitationList extends mixins(
  PaginationMixin,
  InvitationAction
) {
  sortHeaders = [
    { placeholder: 'Email', sortColumn: 'email', sort: true },
    {
      placeholder: 'Invitation Type',
      sortColumn: 'latestActivity',
      sort: true
    },
    { placeholder: 'Application', sortColumn: 'applicationId', sort: true },
    {
      placeholder: 'Invitation Date',
      sortColumn: 'invitationDate',
      sort: true
    },
    { placeholder: 'Status', sort: false }
  ];
  @Prop() public items!: Invitation[];
  @Prop(Number) public currentPage!: number;
  @Prop(Number) public totalNumberOfPage!: number;
  @Prop(Number) public totalCount!: number;
  @Prop(Boolean) public isFirstPage!: boolean;
  @Prop(Boolean) public isLastPage!: boolean;
  @Prop(Number) public startItemIndex!: number;
  @Prop(Number) public endItemIndex!: number;
  @Prop(String) public sortColumn!: string;
  @Prop(String) public sortOrder!: 'ASC' | 'DESC';

  @State(({ admin }: RootState) => admin.apiState.getInvitations.success)
  public getUserInvitationsSuccess!: boolean;

  get dayjs() {
    return dayjs;
  }

  public getInvitationType(status: InvitationType) {
    return `${status.charAt(0).toUpperCase() + status.slice(1)} Invitation`;
  }

  public status({ acceptedAt, expiredAt }: Invitation) {
    if (acceptedAt) {
      return 'Redeemed';
    }

    const expiredDate = moment(expiredAt);
    const now = moment();
    const days = expiredDate.diff(now, 'days');
    const hours = expiredDate.diff(now, 'hours');
    const minutes = expiredDate.diff(now, 'minutes');
    const remainingDate = { days, hours, minutes };

    if (days <= 7) {
      const key = Object.keys(remainingDate).find(
        // @ts-ignore
        (k) => remainingDate[k] > 0
      );

      if (key) {
        // @ts-ignore
        return `Expiring (${remainingDate[key]} ${key})`;
      }
      return 'Expired';
    }

    return 'Invited';
  }

  public onClickHeaderColumn(columnName: string) {
    if (this.sortColumn === columnName) {
      return this.$emit('sort', {
        sortColumn: columnName,
        sortOrder: this.sortOrder === 'ASC' ? 'DESC' : 'ASC'
      });
    }
    this.$emit('sort', {
      sortColumn: columnName,
      sortOrder: 'DESC'
    });
  }

  public getSortOrderOfColumn(name: string) {
    if (this.sortColumn === name) {
      return this.sortOrder;
    }
    return 'ASC';
  }
}
