



















import { Component, Vue, Watch, Prop } from 'vue-property-decorator';
import { Action, State } from 'vuex-class';
import { ValidationObserver } from 'vee-validate';
import { RootState } from '@/store/store';
import BaseModal from '@/jbi-shared/vue-components/BaseModal.vue';
import InviteUserForm, {
  InviteUserFormValues
} from '@/views/AdminUserManagement/components/InviteUserForm.vue';
import { isDifferent, isTruthy } from '@/jbi-shared/util/watcher.vue-decorator';
import { InviteUserToProductPayload } from '@/store/modules/admin/types/admin.types';
import { InvitationListUploader } from '@/views/AdminUserManagement/components/invitations';

@Component({
  components: {
    BaseModal,
    InviteUserForm,
    ValidationObserver,
    InvitationListUploader
  }
})
export default class ImportUserListButton extends Vue {
  @Prop() public products!: object;
  @Prop() public text!: string;

  @Action('admin/inviteNewUserToProduct')
  public inviteNewUserToProduct!: (payload: InviteUserToProductPayload) => void;

  public handleInviteUser(values: InviteUserFormValues) {
    const { email, username, firstName, lastName, productId } = values;
    this.inviteNewUserToProduct({
      email,
      username,
      firstName,
      lastName,
      productId
    });
  }
}
