


















































































































import { Vue, Component, Watch, Prop } from 'vue-property-decorator';
import { Action, State } from 'vuex-class';
import { mixins } from 'vue-class-component';
import { RootState } from '@/store/store';
import dayjs from 'dayjs';
import {
  Invitation,
  Pagination
} from '@/store/modules/admin/types/admin.types';
import { InvitationAction } from '../mixins/invitation-action.mixin';
import { ApiState } from '@/store/types/general.types';
import { ToastProgrammatic as Toast } from 'buefy';
import { isDifferent, isTruthy } from '@/jbi-shared/util/watcher.vue-decorator';
import { AxiosError } from 'axios';
import { get as _get } from 'lodash';

@Component({})
export default class UserInvitationHistoryList extends mixins(
  InvitationAction
) {
  @Prop() public email!: string;

  @State((state: RootState) => state.admin.userInvitations)
  public userInvitations!: Pagination<Invitation>;

  @Action('admin/getUserInvitations')
  public getUserInvitations!: (params: { email: string }) => Promise<void>;

  @State((state: RootState) => state.admin.apiState.getUserInvitations)
  public getUserInvitationsState!: ApiState;

  @State((state: RootState) => state.admin.apiState.removeUserInvitation)
  public removeUserInvitationState!: ApiState;

  get invitations() {
    if (this.userInvitations) {
      return this.userInvitations.items || [];
    }
    return [];
  }

  public async mounted() {
    await this.getUserInvitations({ email: this.email });
  }

  public getStatus(invitation: Invitation) {
    const { expiredAt, acceptedAt } = invitation;
    const expiryDate = dayjs(new Date(expiredAt));
    const expired = dayjs().isAfter(expiredAt);
    if (acceptedAt) {
      return 'Accepted';
    }
    return expired ? 'Expired' : 'Pending';
  }

  get dayjs() {
    return dayjs;
  }

  @Watch('removeUserInvitationState.success')
  @isDifferent
  @isTruthy
  public onRemoveSuccess() {
    Toast.open({
      queue: true,
      type: 'is-dark',
      position: 'is-top',
      message: `Remove User Invitation Successfully`
    });
    this.getUserInvitations({ email: this.email });
  }

  @Watch('removeUserInvitationState.error')
  @isDifferent
  @isTruthy
  public onRemoveError(error: AxiosError) {
    Toast.open({
      queue: true,
      type: 'is-danger',
      position: 'is-top',
      message: `Error Remove User Invitation. Error: ${_get(
        error.response,
        'data.message'
      )}`
    });
  }
}
