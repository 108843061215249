import { JAAS_APP } from '../types/jaas-app.types';

export interface GenerateJoinInvitationLinkPayload {
  dashboardUrl: string;
  token: string;
  email: string;
}
export const generateJoinInvitationLink = ({
  dashboardUrl,
  token,
  email,
}: GenerateJoinInvitationLinkPayload) =>
  `${dashboardUrl}?token=${encodeURIComponent(
    token,
  )}&email=${encodeURIComponent(email)}`;

export interface GenerateProductInvitationLinkPayload {
  dashboardUrl: string;
  token: string;
  email: string;
  applicationId: JAAS_APP;
  productId: number;
}
export const generateProductInvitationLink = ({
  dashboardUrl,
  applicationId,
  productId,
  token,
  email,
}: GenerateProductInvitationLinkPayload) =>
  `${dashboardUrl}/application/${encodeURIComponent(
    applicationId,
  )}/products?product_id=${productId}&token=${encodeURIComponent(
    token,
  )}&email=${encodeURIComponent(email)}`;
