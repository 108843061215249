






























































































import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import { State, Action } from 'vuex-class';
import { User } from '@/store/modules/subscription/subscription.state';
import { RootState } from '@/store/store';
import InvitationList from './components/InvitationList.vue';
import BasePaginatorHoc from '@/components/base/BasePaginatorHoc.vue';
import {
  Pagination,
  GetInvitationsPayload,
  Invitation,
  GetInvitationsPayloadSort,
  InvitationsTabFilterPayload
} from '../../store/modules/admin/types/admin.types';
import { Application } from '../../store/modules/application/types/application.types';
import { Debounce } from '@/jbi-shared/util/debounce.vue-decorator';
import { ApiState } from '../../store/types/general.types';
import { get as _get } from 'lodash';
import { ToastProgrammatic as Toast } from 'buefy';
import { isDifferent, isTruthy } from '@/jbi-shared/util/watcher.vue-decorator';
import { AxiosError } from 'axios';
import { InvitationType } from '../../jbi-shared/types/jaas-invitation.types';

const getInitFormParams = () => ({
  email: '',
  latestActivity: '',
  applicationId: '0',
  invitationStatus: ''
});
const sortColumnList = () => [
  'invitationDate',
  'applicationId',
  'latestActivity',
  'email'
];

@Component({
  components: {
    BasePaginatorHoc
  }
})
export default class InvitationTab extends Vue {
  public perPage = 50;
  public page = 1;
  public params = getInitFormParams();
  public sortColumn: string = 'email';
  public sortOrder: 'ASC' | 'DESC' = 'ASC';

  @Action('admin/getInvitations')
  public getInvitations!: (params: GetInvitationsPayload) => Promise<void>;

  @State(({ admin }: RootState) => admin.apiState.getInvitations)
  public getInvitationStatus!: ApiState;

  @State((state: RootState) => state.admin.apiState.inviteNewUserToProduct)
  public inviteNewUserToProductState!: ApiState;

  @State((state: RootState) => state.admin.apiState.bulkInviteNewUserToProduct)
  public bulkInviteNewUserToProductState!: ApiState;

  @State((state: RootState) => state.admin.apiState.generateInvitationLinks)
  public generateInvitationLinksState!: ApiState;

  @State((state: RootState) => state.admin.apiState.removeInvitation)
  public removeInvitationState!: ApiState;

  @State(
    ({ application }: RootState) =>
      application.productApplications?.applications
  )
  private applications!: Application[];

  @State(({ admin }: RootState) => admin.invitations)
  private invitations!: Pagination<Invitation>;

  get activitiesOptions() {
    return [
      { label: 'All Invitation Types', value: '' },
      { label: 'System Invitation', value: InvitationType.SYSTEM },
      { label: 'Product Invitation', value: InvitationType.PRODUCT },
      { label: 'Group Invitation', value: InvitationType.GROUP }
    ];
  }

  get applicationOptions() {
    if (this.applications) {
      return [
        { id: 0, name: 'All Applications' },
        ...this.applications.map(({ id, name }) => ({
          id,
          name
        }))
      ];
    }
    return [];
  }

  get statusesOptions() {
    return [
      { label: 'All Invitation Statuses', value: '' },
      { label: 'Redeemed', value: 'redeemed' },
      { label: 'Invited', value: 'invited' },
      { label: 'Expired', value: 'expired' },
      { label: 'Expiring', value: 'expiring' }
    ];
  }

  get InvitationList() {
    return InvitationList;
  }

  public resetFilter() {
    this.params = getInitFormParams();
  }

  public async handleSort(data: GetInvitationsPayloadSort) {
    this.sortColumn = data.sortColumn;
    this.sortOrder = data.sortOrder;

    const params = {
      email: (this.$route.query.email as string) || this.params.email,
      latestActivity:
        (this.$route.query.latestActivity as string) ||
        this.params.latestActivity,
      applicationId:
        (this.$route.query.applicationId as string) ||
        this.params.applicationId,
      invitationStatus:
        (this.$route.query.invitationStatus as string) ||
        this.params.invitationStatus,
      limit: +this.$route.query.limit || this.perPage,
      page: +this.$route.query.page || this.page,
      sortColumn: this.sortColumn,
      sortOrder: this.sortOrder
    };

    await this.getInvitations(params);

    this.$router.push({
      path: this.$route.path,
      query: {
        tab: 'Invitations',
        email: this.$route.query.email || this.params.email,
        latestActivity:
          this.$route.query.latestActivity || this.params.latestActivity,
        applicationId:
          this.$route.query.applicationId ||
          this.params.applicationId.toString(),
        invitationStatus:
          this.$route.query.invitationStatus || this.params.invitationStatus,
        limit: (this.$route.query.limit as string) || this.perPage.toString(),
        page: (this.$route.query.page as string) || this.page.toString(),
        sortColumn: this.sortColumn,
        sortOrder: this.sortOrder,
        username: this.$route.query.username
      }
    });
  }

  public async handlePaginator({
    perPage,
    page
  }: {
    perPage: number;
    page: number;
  }) {
    if (this.$route.query.tab !== 'Invitations') {
      return;
    }
    this.perPage = perPage || +this.$route.query.limit;
    this.page = page || +this.$route.query.page;

    const params = {
      email: (this.$route.query.email as string) || this.params.email,
      latestActivity:
        (this.$route.query.latestActivity as string) ||
        this.params.latestActivity,
      applicationId:
        (this.$route.query.applicationId as string) ||
        this.params.applicationId,
      invitationStatus: this.params.invitationStatus || undefined,
      limit: this.perPage,
      page: this.page,
      sortColumn: (this.$route.query.sortColumn as string) || this.sortColumn,
      sortOrder:
        (this.$route.query.sortOrder as 'ASC' | 'DESC') || this.sortOrder
    };

    await this.getInvitations(params);

    this.$router.push({
      path: this.$route.path,
      query: {
        tab: 'Invitations',
        email: this.$route.query.email || this.params.email,
        latestActivity:
          this.$route.query.latestActivity || this.params.latestActivity,
        applicationId:
          this.$route.query.applicationId ||
          this.params.applicationId.toString(),
        invitationStatus:
          this.$route.query.invitationStatus || this.params.invitationStatus,
        limit: this.perPage.toString(),
        page: this.page.toString(),
        sortColumn: (this.$route.query.sortColumn as string) || this.sortColumn,
        sortOrder:
          (this.$route.query.sortOrder as 'ASC' | 'DESC') || this.sortOrder,
        username: this.$route.query.username
      }
    });
  }

  public async handleFilter() {
    this.perPage = +this.$route.query.limit;
    const queryFilter: InvitationsTabFilterPayload = {
      email: this.$route.query.email as string,
      latestActivity: this.$route.query.latestActivity as string,
      applicationId: this.$route.query.applicationId as string,
      invitationStatus: this.$route.query.invitationStatus as string
    };

    if (JSON.stringify(queryFilter) !== JSON.stringify(this.params)) {
      this.page = 1;

      const params = {
        email: this.params.email,
        latestActivity: this.params.latestActivity,
        applicationId: this.params.applicationId,
        invitationStatus: this.params.invitationStatus,
        limit: +this.$route.query.limit || this.perPage,
        page: 1,
        sortColumn: (this.$route.query.sortColumn as string) || this.sortColumn,
        sortOrder:
          (this.$route.query.sortOrder as 'ASC' | 'DESC') || this.sortOrder
      };

      await this.getInvitations(params);
      this.$router.push({
        path: this.$route.path,
        query: {
          tab: 'Invitations',
          email: this.params.email,
          latestActivity: this.params.latestActivity,
          applicationId: this.params.applicationId
            ? this.params.applicationId.toString()
            : '0',
          invitationStatus: this.params.invitationStatus,
          limit: (this.$route.query.limit as string) || this.perPage.toString(),
          page: (this.$route.query.page as string) || this.page.toString(),
          sortColumn:
            (this.$route.query.sortColumn as string) || this.sortColumn,
          sortOrder:
            (this.$route.query.sortOrder as 'ASC' | 'DESC') || this.sortOrder,
          username: this.$route.query.username
        }
      });
    }
  }
  public async handleResetQuery() {
    const params = {
      email: this.$route.query.email as string,
      latestActivity: this.$route.query.latestActivity as string,
      applicationId: this.$route.query.applicationId as string,
      invitationStatus: this.params.invitationStatus,
      limit: +this.$route.query.limit,
      page: +this.$route.query.page,
      sortColumn: sortColumnList().includes(
        this.$route.query.sortColumn as string
      )
        ? (this.$route.query.sortColumn as string) || this.sortColumn
        : this.sortColumn,
      sortOrder:
        (this.$route.query.sortOrder as 'ASC' | 'DESC') || this.sortOrder
    };
    this.params = params;
  }
  public mounted() {
    if (this.$route.query.tab === 'Invitations') {
      this.params = {
        email: (this.$route.query.email as string) || this.params.email,
        latestActivity:
          (this.$route.query.latestActivity as string) ||
          this.params.latestActivity,
        applicationId:
          (this.$route.query.applicationId as string) ||
          this.params.applicationId,
        invitationStatus:
          (this.$route.query.invitationStatus as string) ||
          this.params.invitationStatus
      };

      this.$router.push({
        path: this.$route.path,
        query: {
          tab: 'Invitations',
          email: this.$route.query.email || this.params.email,
          latestActivity:
            this.$route.query.latestActivity || this.params.latestActivity,
          applicationId:
            (this.$route.query.applicationId as string) ||
            this.params.applicationId.toString(),
          invitationStatus:
            this.$route.query.invitationStatus || this.params.invitationStatus,
          limit: (this.$route.query.limit as string) || this.perPage.toString(),
          page: (this.$route.query.page as string) || this.page.toString(),
          sortColumn: sortColumnList().includes(
            this.$route.query.sortColumn as string
          )
            ? (this.$route.query.sortColumn as string) || this.sortColumn
            : this.sortColumn,
          sortOrder:
            (this.$route.query.sortOrder as 'ASC' | 'DESC') || this.sortOrder,
          username: this.$route.query.username as string
        }
      });

      this.perPage = +this.$route.query.limit || this.perPage;
      this.page = +this.$route.query.page || this.page;
      this.handleResetQuery();
    }
  }

  public async handleTabResetQuery() {
    this.handleResetQuery();
  }

  @Watch('getInvitationStatus.success')
  @Debounce(400)
  @isDifferent
  @isTruthy
  public getInvitationStatusSuccess() {
    this.$emit('activateTab');
  }

  @Watch('getInvitationStatus.error')
  @isDifferent
  @isTruthy
  public getInvitationStatusError() {
    this.$emit('activateTab');
  }

  @Watch('$route.query.tab')
  public routeParam() {
    if (this.$route.query.tab === 'Invitations') {
      this.handleTabResetQuery();
    }
  }

  @Watch('params', { deep: true })
  @Debounce(500)
  public onFilterChanged() {
    this.handleFilter();
  }

  @Watch('inviteNewUserToProductState.success')
  @Watch('bulkInviteNewUserToProductState.success')
  @Watch('generateInvitationLinksState.success')
  public onInviteSuccess() {
    this.handleResetQuery();
  }

  @Watch('removeInvitationState.success')
  @isDifferent
  @isTruthy
  public onRemoveSuccess() {
    Toast.open({
      queue: true,
      type: 'is-dark',
      position: 'is-top',
      message: `Remove Invitation Successfully`
    });
    this.handleResetQuery();
  }

  @Watch('removeInvitationState.error')
  @isDifferent
  @isTruthy
  public onRemoveError(error: AxiosError) {
    Toast.open({
      queue: true,
      type: 'is-danger',
      position: 'is-top',
      message: `Error Remove Invitation. Error: ${_get(
        error.response,
        'data.message'
      )}`
    });
  }
}
