










































































import { Component, Vue, Watch, Prop } from 'vue-property-decorator';
import { Action, State } from 'vuex-class';
import { ValidationObserver } from 'vee-validate';
import { RootState } from '@/store/store';
import BaseModal from '@/jbi-shared/vue-components/BaseModal.vue';
import { isDifferent, isTruthy } from '@/jbi-shared/util/watcher.vue-decorator';
import ProductForm from '@/views/ProductManagement/components/ProductForm.vue';
import {
  ProductFormValues,
  Product,
  DeleteProductValues
} from '../../../store/modules/application/types/application.types';
import EditSubscriptionForm from './EditSubscriptionForm.vue';
import {
  GetSubscriptionsPayload,
  Subscription
} from '@/store/modules/admin/types/user-subscription.types';
import { SubscriptionStatus } from '@/jbi-shared/types/jaas-oauth2.types';
import { ExtendProductSubscriptionPayload } from '@/store/modules/subscription/subscription.state';

@Component({
  components: {
    BaseModal,
    EditSubscriptionForm,
    ValidationObserver
  }
})
export default class EditSubscriptionsButton extends Vue {
  @Prop() public subscription!: Subscription;

  @Action('subscription/extendProductSubscription')
  public extendProductSubscription!: (
    data: ExtendProductSubscriptionPayload
  ) => Promise<void>;

  get getProducts(): Product[] {
    const products =
      (this.$store.state as RootState).application.productApplications
        ?.products || [];
    return products.filter((product) => product.isActive && !product.isDeleted);
  }

  get checkSubscriptions() {
    if (
      this.getProducts.find(
        (product) =>
          product.applicationId === this.subscription.applicationId &&
          product.id === this.subscription.productId &&
          product.currentPrice.amount === 0
      )
    ) {
      return true;
    }

    return false;
  }

  public async handleSubmit(
    subscription: Subscription,
    form: Pick<ProductFormValues, 'exclusionDuration' | 'exclusionDurationType'>
  ) {
    const payload: ExtendProductSubscriptionPayload = {
      applicationId: subscription.applicationId,
      productId: subscription.productId!,
      exclusionDuration: form.exclusionDuration,
      exclusionDurationType: form.exclusionDurationType
    };

    await this.extendProductSubscription(payload);
    this.$emit('handleResetQuery');
  }

  public handleCloseModal() {
    // @ts-ignore
    this.$refs.SubscriptionForm.closeModal();
  }

  // we use getter to use the enum values in vue
  get getSubscriptionStatus() {
    return SubscriptionStatus;
  }

  public toggleSubscriptions() {
    this.$emit('toggle', this.subscription);
  }
}
