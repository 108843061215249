var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ValidationObserver',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('form',{on:{"submit":function($event){$event.preventDefault();return (function () {
        if (!invalid) {
          _vm.$refs.SubscriptionForm.handleSubmit();
          _vm.$refs.SubscriptionForm.closeModal();
        }
      })($event)}}},[_c('BaseModal',{attrs:{"title":"Extend Subscription Period","size":"small"},scopedSlots:_vm._u([{key:"openBtn",fn:function(ref){
      var openModal = ref.openModal;
return [_c('b-dropdown',{class:['is-left', _vm.$style.dropdownContainer],attrs:{"aria-role":"list"},scopedSlots:_vm._u([{key:"trigger",fn:function(){return [_c('a',{class:['icon', 'fa', 'fa-ellipsis-h', _vm.$style.ellipsisColor],attrs:{"role":"button"}})]},proxy:true}],null,true)},[_c('b-dropdown-item',{attrs:{"aria-role":"listitem"},on:{"click":_vm.toggleSubscriptions}},[_vm._v(_vm._s(_vm.subscription.subscriptionStatus === _vm.getSubscriptionStatus.ACTIVE ? 'Pause' : 'Enable')+" Subscription")]),(
              _vm.subscription.subscriptionStatus ===
                _vm.getSubscriptionStatus.ACTIVE && _vm.checkSubscriptions
            )?_c('b-dropdown-item',{attrs:{"aria-role":"listitem"},on:{"click":openModal}},[_vm._v(" Extend Subscription Period")]):_vm._e()],1)]}},{key:"modalBody",fn:function(ref){
            var closeModal = ref.closeModal;
return [_c('EditSubscriptionForm',{ref:"SubscriptionForm",attrs:{"subscription":_vm.subscription},on:{"submit":function (subs, form) { return _vm.handleSubmit(subs, form); },"closeModal":closeModal}})]}},{key:"modalFoot",fn:function(ref){
            var closeModal = ref.closeModal;
return [_c('div',{class:_vm.$style.cancelButton,on:{"click":_vm.handleCloseModal}},[_vm._v(" Cancel ")]),_c('button',{staticClass:"button is-primary",attrs:{"data-test":"edit-product-button","type":"submit","disabled":invalid},on:{"closeModal":closeModal}},[_vm._v(" Save ")])]}}],null,true)})],1)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }